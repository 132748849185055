import { AllCheckerCheckbox, Checkbox, } from '@createnl/grouped-checkboxes';
import moment from 'moment';

import { Images, Colors, } from '../themes'

import { SAFE_RETURN_STATUS, } from '../constants/'

export const partnerColumns = ({ onClickColumn }) => {
  return [
    {
      Header: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}> <AllCheckerCheckbox style={{ cursor: 'pointer', }} /></div>,
      Cell: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}>  <Checkbox style={{ cursor: 'pointer', }} value={props.original}/></div>, 
      headerClassName: 'table-header',
      className: 'column-checkbox',
      width: 40,
      sortable: false,    
      resizable: false,
    },
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Reported PLWD</span>,
      Cell: props => <span className="column-text">{props && props.original && props.original.dementia ? `${props.original.dementia.firstname} ${props.original.dementia.lastname}` : ''}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Reporter Name</span>,
      Cell: props => <span className="column-text">{props.original.ReturnCard && props.original.ReturnCard.name}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    },
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Phone No.</span>,
      Cell: props => <span className="column-text">{props.original.ReturnCard && props.original.ReturnCard.phone}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    },  
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Location</span>,
      Cell: props => <span className="column-text">{props.original.ReturnCard && props.original.ReturnCard.location}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Report Date</span>,
      Cell: props => <span className="column-text">{moment(props.original.ReturnCard && props.original.ReturnCard.createdAt).format(('DD-MM-YY h:mm a'))}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Status</span>,
      Cell: props => {
        let status = 
          props.original.ReturnCard && props.original.ReturnCard.status === SAFE_RETURN_STATUS.PENDING ? { text: `Pending`, color: Colors.color14 }
          : { text: `Action Taken`, color: Colors.color17 }
        return <span style={{ color: status.color }} className="column-text">{status.text}</span>
      }, 
      headerClassName: 'table-header',
      width: 100,
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      headerClassName: 'table-header',
      className: 'actions-column',
      width: 40,
      Cell: row => {
        return (
           <div className="icon-container">
            <div className="dots-wrapper" onClick={() => onClickColumn(row)}>
              <img src={Images.threeDots} className="delete" />
            </div>
           </div>
        )
      },
      sortable: false,      
      resizable: false,
    }]
}


export const adminColumns = ({ onClickColumn }) => {
  return (  
    [
      {
        Header: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}> <AllCheckerCheckbox style={{ cursor: 'pointer', }} /></div>,
        Cell: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}>  <Checkbox style={{ cursor: 'pointer', }} value={props.original}/></div>, 
        headerClassName: 'table-header',
        className: 'column-checkbox',
        width: 40,
        sortable: false,    
        resizable: false,
      },
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Reported PLWD</span>,
        Cell: props => <span className="column-text">{ props && props.original && props.original.dementia ? `${props.original.dementia.firstname} ${props.original.dementia.lastname}` : ''}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Reporter Name</span>,
        Cell: props => <span className="column-text">{props.original.name}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      },
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Phone No.</span>,
        Cell: props => <span className="column-text">{props.original.phone}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      },  
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Location</span>,
        Cell: props => <span className="column-text">{props.original.location}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Report Date</span>,
        Cell: props => <span className="column-text">{moment(props.original.createdAt).format(('DD-MM-YY h:mm a'))}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Status</span>,
        Cell: props => {
          let status = 
            props.original.status === SAFE_RETURN_STATUS.PENDING ? { text: `Pending`, color: Colors.color14 }
            : { text: `Action Taken`, color: Colors.color17 }
          return <span style={{ color: status.color }} className="column-text">{status.text}</span>
        }, 
        headerClassName: 'table-header',
        width: 100,
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        headerClassName: 'table-header',
        className: 'actions-column',
        width: 40,
        Cell: row => {
          return (
              <div className="icon-container">
              <div className="dots-wrapper" onClick={() => onClickColumn(row)}>
                <img src={Images.threeDots} className="delete" />
              </div>
              </div>
          )
        },
        sortable: false,      
        resizable: false,
      }
    ]
  )
} 