import { API } from '../lib/Request'
// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

const notificationsUrl = AppConfig.NOTIFICATIONS_URL

export const addNotification = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${notificationsUrl}/notifications`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const deleteNotification = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${notificationsUrl}/notifications/${data.id}`, method: 'DELETE', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const deleteNotificationByQuery = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${notificationsUrl}/delete-notification`, method: 'DELETE', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}
