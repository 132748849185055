import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";


function Chart({ rawdata, color, radius, cutout }) {
    const initData = {
      datasets: [
        {
          data: [0, 0],
          backgroundColor: color,
          hoverBackgroundColor: color,
        },
      ],
    };
    const [data, setData] = useState(initData);

  
    useEffect(() => {
      setData({
        datasets: [
          {
            data: rawdata,
            backgroundColor: color,
            hoverBackgroundColor: color,
            cutout: cutout,
            radius: radius
          },
        ],
      });
    }, [rawdata]);
  
    const options = {
      responsive: false,
      animation: false,
      Animation:false,
    };
    return (
      <div style={{display: "flex", position: "absolute", flexDirection: 'row'}}>
        <Doughnut data={data} options={options} />
      </div>
    );
  }
  export default Chart;