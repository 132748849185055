import React from 'react'
import PropTypes from 'prop-types'

import {Container, StyledButton, StyledDropdown } from './StyledButton'
import { Colors, Images, } from '../../themes'
import edit from '../../assets/images/edit.png'

function Button({ text, iconRightStyle, onClick, buttonStyle, textStyle, backgroundColor, textColor, bordered, borderColor, iconRight, iconRightImage, containerStyle, showDropdown, dropdownData, dropdownHeight, onSelectSort, disabled, dropdownWidth, selectedData, sortPropertyName, styleHoverDropdown, iconLeftImage, iconLeftStyle, isPlwd, isDownloadReport, dropdowStyle}) {
  return (
    <Container containerStyle={containerStyle}>
      <StyledButton onClick={disabled ? null : onClick} buttonStyle={buttonStyle} backgroundColor={backgroundColor} textColor={textColor} bordered={bordered} borderColor={borderColor} textStyle={textStyle} disabled={disabled} iconRightStyle={iconRightStyle} iconLeftStyle={iconLeftStyle}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%'
          }}
        >
          <div>
            <p className="button-text">{text}</p>
          </div>
          <div>
            {
              iconRight &&
              <img src={iconRightImage} style={{marginTop: '5px'}} alt="icon" className="icon-right"  />
            }
          </div>
        </div>
      </StyledButton>
      <StyledDropdown showDropdown={showDropdown} styleHoverDropdown={styleHoverDropdown} dropdownHeight={dropdownHeight} dropdownWidth={dropdownWidth} dropdowStyle={dropdowStyle}>
        <ul>
            {
                dropdownData.map((data, index) => {
                  return (
                    <div style={{ background: selectedData === data[sortPropertyName] ? Colors.color10  : Colors.color3 }}>
                      <li key={index.toString()} onClick={() => onSelectSort(data)} style={{ color: selectedData === data[sortPropertyName] ? Colors.color3 : '' }}>{data[sortPropertyName]}</li>
                    </div>
                  )
                })
            }
        </ul>
      </StyledDropdown>
    </Container>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.any,
  textStyle: PropTypes.any,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  bordered: PropTypes.bool,
  borderColor: PropTypes.string,
  iconRight: PropTypes.bool,
  iconRightImage: PropTypes.string,
  containerStyle: PropTypes.string,
  showDropdown: PropTypes.bool,
  dropdownData: PropTypes.array,
  dropdownHeight: PropTypes.string,
  onSelectSort: PropTypes.func,
  disabled: PropTypes.bool,
  dropdownWidth: PropTypes.string,
  selectedData: PropTypes.string,
  iconLeftImage: PropTypes.any,
  iconLeftStyle: PropTypes.string,
  isPlwd: PropTypes.bool,
}

Button.defaultProps = {
  text: '',
  onClick: () => null,
  buttonStyle: '',
  buttonStyle: '',
  textStyle: '',
  backgroundColor: Colors.color8,
  textColor: Colors.color3,
  bordered: false,
  borderColor: Colors.color11,
  iconRight: false,
  iconRightImage: Images.polygon,
  iconRightStyle: 'width: 12px; height: 8px;',
  containerStyle: '',
  showDropdown: false,
  dropdownData: [
    {
      id: 1,
      name: 'Approved PLWD',
      sortBy: 'APPROVED_PLWD',
    },
    {
      id: 2,
      name: 'Approved CG',
      sortBy: 'APPROVED_CG',
    },
    {
      id: 3,
      name: 'PLWD Profile Picture',
      sortBy: 'PLWD_PROFILE_PICTURE',
    },
    {
      id: 4,
      name: 'PLWD Multiple CG',
      sortBy: 'PLWD_MULTIPLE_CG',
    },
    {
      id: 5,
      name: 'CG Multiple PLWD',
      sortBy: 'CG_MULTIPLE_PLWD',
    },
    {
      id: 6,
      name: 'Incomplete Same Address',
      sortBy: 'INCOMPLETE_SAME_ADDRESS',
    },
    {
      id: 7,
      name: 'MOP',
      sortBy: 'MOP',
    },
    {
      id: 8,
      name: 'Incomplete Address',
      sortBy: 'INCOMPLETE_ADDRESS',
    }
  ],
  dropdownHeight: '110px',
  onSelectSort: () => null,
  disabled: false,
  dropdownWidth: '87%',
  selectedData: '',
  sortPropertyName: 'name',
  styleHoverDropdown:'',
  iconLeftImage: null,
  iconLeftStyle: 'width: 12px; height: 8px;',
}

export default Button

