import React, { Component, createRef} from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, } from 'react-router-dom'
import memoize from "memoize-one";
import moment from 'moment'

import { StyledNotifications, } from './styles/StyledNotifications'

import Button from '../components/button'
import Table from '../components/table'
import Dialog from '../components/modal'
import DialogInfo from '../components/dialog-info'

import { Colors, Images, } from '../themes'

import ProccessData from '../lib/ProccessData'

import NotificationsActions from '../redux/NotificationsRedux'

import { deleteNotification } from '../api/NotificationAPI'
import { USER_TYPE } from '../constants';


class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      notificationData: [],
      dialogConfirmation: false,
      loadingDelete: false,
      notificationId: '',
      page: 0,
      pageSize: 5,
      infoDescription: '',
      infoDialogShow: false,
    }

    this.state.pages = 0
  }
  
  componentDidMount() {
    this.setState({ loading: true })

    this.props.getNotifications({ data: { type: 'NOTIFICATIONS', } })
  }

  setData = memoize(data => {
    ProccessData(data, 'get notifications')
    .then(res => {
      let notificationData = [] 
      res.payload.data.map(data => notificationData.push(data))
      this.setState({
        loading: false,
        errorText: '',
        notificationData,
        
      })
      
      if(this.state.notificationData){

        this.setState({
          pages : Math.ceil(this.state.notificationData.length / this.state.pageSize)
        })
      }
    })
    .catch(err => {
      this.onDialogInfo(err.message)
      this.setState({
        errorText: err ? err : 'Something wrong, please check your internet connection',
        loading: false,
      })
    })
  })

  deleteNotification = async (id) => {
    this.setState({ loadingDelete: true })
    try {
      let resultDelete = {}
      resultDelete.fetching = false
      resultDelete.payload = await deleteNotification({ id })
      ProccessData(resultDelete, 'delete notification')
      .then(res => {
        this.setState({ 
          loadingDelete: false,
          dialogConfirmation: false,
          loading: true,
         })
         this.onDialogInfo('Notification Deleted')
         this.props.getNotifications({ data: { type: 'NOTIFICATIONS', } })
      })
      .catch(err => {
        this.setState({
          dialogConfirmation: false,
          loadingDelete: false,
        })
        this.onDialogInfo(err.message)
      })
    } catch (err) {
      this.setState({
        loadingDelete: false,
        dialogConfirmation: false,
      })
      this.onDialogInfo(err.message)
    }
  }
  
  handleUpdate = pageIndex => {
    this.setState({ page: pageIndex });
  };

  onDialogInfo = (val) => {
    this.setState({infoDescription: val, infoDialogShow: true})
  }

  render() {
    const { notificationData, loading, dialogConfirmation, infoDescription, infoDialogShow, loadingDelete, notificationId,page, pages, pageSize } = this.state
    this.setData(this.props.notifications)

    const columns = [
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>No</span>,
        Cell: props => <span className="column-text">{props.index + 1}</span>, 
        headerClassName: 'table-header header-no',
        className: 'column text-no',
        width: 70,    
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Notification</span>,
        Cell: props => <span className="column-text">{props.original.title}</span>, 
        headerClassName: 'table-header',
        className: 'column',
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Status</span>,
        Cell: props => <span className="column-text">{props.original.status}</span>, 
        headerClassName: 'table-header',
        className: 'column',
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Platform</span>,
        Cell: props => <span className="column-text">{props.original.platform}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 120,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Start/Send</span>,
        Cell: props => <span className="column-text">{props.original.NotificationSchedule ? moment(props.original.NotificationSchedule.dateSend).format('DD/MM/YYYY, h:mm A'): '-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 180,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>End</span>,
        Cell: props => <span className="column-text">{'-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Push Notifications</span>,
        Cell: props => <span className="column-text">{parseInt(props.original.total) < 1000 ? `<1000` : `>1000`}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 150,
      },
      // {
      //   Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Opens</span>,
      //   Cell: props => <span className="column-text">{`${props.original.opened.toString().replace(/\.00$/,'')}%`}</span>, 
      //   headerClassName: 'table-header',
      //   className: 'column',
      // },  
      ]

      if(this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN) {
        columns.push({
          headerClassName: 'table-header',
          className: 'actions-column',
          width: 40,
          Cell: row => {
            return (
               <div className="icon-container">
                <div className="trash-wrapper" onClick={() => this.setState({ dialogConfirmation: true, notificationId: row.original.id, })}>
                  <img src={Images.trash} className="delete" />
                </div>
               </div>
            )
          }
        });
      }


    return (
      <StyledNotifications>
        <Dialog
          isVisible={dialogConfirmation}
          isClose={() => this.setState({ dialogConfirmation: false, })}
          title="Delete Notification"
          showXClose={false}
          additionalStyle={{ titleStyle: ` color: ${Colors.color13} !important; ` }}
          description={`Are you sure you want to delete this notification?`}
          width={`37%`}
          height={`auto`}
          cancelText={`Decline`}
          okText={loadingDelete ? `Loading...` : `Delete`}
          okBorderColor={Colors.color13}
          okTextColor={Colors.color13}
          onOk={() => this.deleteNotification(notificationId)}
        />
        {/* Dialog info start */}
        <DialogInfo
          textInfo={infoDescription}
          isVisible={infoDialogShow}
          onOkButton={() => this.setState({infoDialogShow: false} )}
        />
        {/* Dialog info end  */}

        <div className="title-container">
          <h1 className="title">Notifications</h1>
          {
            (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
              <Button
                text="Create New Notification"
                buttonStyle={` width: 315px; `}
                textStyle={` font-weight: 800; `}
                onClick={() => this.props.history.push('/create-notification')}
              />
            )
          }
        </div>
        <Table 
          columns={columns}
          responsive
          data={notificationData}

          loading={loading}
          
          page={page}
          pages={pages + 2}
          pageSize={pageSize}
          handleUpdate={this.handleUpdate}
          
          rowLimit={[pageSize]}
            dataLimit={pageSize}
          
        />
      </StyledNotifications>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications.list,
    account: state.auth.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotifications: (params) => dispatch(NotificationsActions.getNotificationsRequest(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
