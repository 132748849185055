import React, { useState, useEffect, } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, } from 'react-grid-system';

// import {  } from './StyledDialogFilterUser'
import { StyledFilterUser } from './filterUserStyle'

import Input from '../input'
import Button from '../button'

import { Colors, Images, } from '../../themes'
import { filter } from 'ramda';

const InputTypeStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  max-width: 100%;
  .input-field { cursor: pointer; }
`;

const checkboxDefaultData = [
  {
    name: 'First Name',
    params: 'firstname',
  },
  { 
    name: 'Last Name',
    params: 'lastname',
  },
  {
    name: 'NRIC',
    params: 'lastNric',
  },
  {
    name: 'Phone Number',
    params: 'phone',
  },
  {
    name: 'Email',
    params: 'email',
  },
  {
    name: 'Membership ID',
    params: 'membershipId',
  },
  {
    name: 'Address',
    params: 'address',
  },
]

const typeOfDementiaData = [
  {
    id: 6,
    name: `Don't Filter`,
  },
  {
    id: 1,
    name: `Alzheimer's Disease`,
  },
  {
    id: 2,
    name:  'Vascular Dementia',
  },
  {
    id: 3,
    name:  'Frontotemporal Dementia (FTD)',
  },
  {
    id: 4,
    name:  'Lewy Body Dementia (LBD)',
  },
  {
    id: 5,
    name:  'Mild Cognitive Impairment (MCI)',
  },
]

const languageData = [
  {
    id: 6,
    name: `Don't Filter`
  },
  {
    id: 1,
    name: 'English',
  },
  {
    id: 2,
    name:  'Mandarin',
  },
  {
    id: 3,
    name:  'Malay',
  },
  {
    id: 4,
    name:  'Tamil',
  },
  {
    id: 5,
    name:  'Hokkien',
  },
]

const genderData = [
  {
    id: 6,
    name: `Don't Filter`
  },
  {
    id: 1,
    name: 'Male',
  },
  {
    id: 2,
    name:  'Female',
  },
]

const InputReadOnlyStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; }
`;

function DialogFilterUser(props) {
  const { 
    dialogProps, 
    checkboxData, 
    onSelectCheckbox, 
    onSelectDropdown, 
    settedFilterBy, 
    onApply, 
    additionalFilter,
    onCheckFilter, 
    onSetAdditionalFilter,
    showDropdownFilter,
  } = props
  
  const [dropdowns,setDropdowns] = useState({
    typeDementia: {
      showDropdown: false,
      value: '',
      text: 'Type of Dementia'
    },
    gender: {
      showDropdown: false,
      value: '',
      text: 'Gender'
    },
    language: {
      showDropdown: false,
      value: '',
      text: 'Preferred Language'
    },
  });

  const [filterBy, setFilterBy] = useState([])

  const [settedDropdown, setSettedDropdown] = useState(false)

  const [inCheckbox, setInCheckbox] = useState(checkboxData)

  const onCheck = (value) => {
    onCheckFilter(value)
    setFilterBy(state => {
      const currentFilterBy = [...state]
      
      if (!currentFilterBy.includes(value.params)) {          //checking weather array contain the id
        currentFilterBy.push(value.params);               //adding to array because value doesnt exists
      } else {
        currentFilterBy.splice(currentFilterBy.indexOf(value.params), 1);  //deleting
      }
      
      return currentFilterBy
    })
  }


  const renderInputCheckbox = (value) => {
    const { name, params } = value
    const checkFilter = filterBy.filter(el=> el === params)
    
    return (
      <Button
        text={name}    
        textColor={Colors.color2}
        borderColor={ checkFilter.length > 0 ? Colors.color36 : Colors.color20 }
        bordered
        iconRight={true}
        iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
        buttonStyle={` font-weight: bold; max-width: 170px; height: 40px; display: flex; margin: 0 10px 0 0; padding: 0 10px `}
        backgroundColor={'transparent'}
        iconRightImage={ checkFilter.length > 0 ? Images.checkActive : Images.checkInactive}
        onClick={() => onCheck(value)}
      />
    );
  }

  const renderInputDropdown = (props) => {
    const { value, onClick, placeholder, dropdownData} = props;
    let filtered = true

    return (
      <Button
        {...props}   
        textColor={Colors.color2}
        bordered
        iconRight
        iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
        buttonStyle={` font-weight: bold; max-width: 200px; height: 40px; display: flex; margin: 10px 10px 10px 0; padding: 0 10px `}
        backgroundColor={'transparent'}
      />
    );
  }

  const onPressDropdown = (stateName) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], showDropdown: !dropdowns[stateName].showDropdown, }}
    })
  };

  const onSelectDataDropdown = (stateName, value) => {
    setDropdowns(state => {
      onSetAdditionalFilter({ [stateName]: value })
      return { ...state, [stateName]: { ...dropdowns[stateName], value, showDropdown: !dropdowns[stateName].showDropdown, }}
    })
    
    setSettedDropdown(true)
  }

  const { typeDementia, gender, language, } = dropdowns
  
  return (
      <StyledFilterUser>
          <div style={{display: 'flex', marginLeft: '-15px'}}>
            {
              checkboxData.map(item => {
              const { name, data } = item
              return (
                  renderInputCheckbox(item)
              )
              })
            }
          </div>
          <div style={{display: 'flex', marginLeft: '-15px'}}>
          {
            showDropdownFilter.typeDementia &&
              renderInputDropdown({
                showDropdown: typeDementia.showDropdown,
                dropdownData: typeOfDementiaData,
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                text: typeDementia.value === ''? 'Type of Dementia': typeDementia.value,
                borderColor: typeDementia.value === ''? Colors.color20: Colors.color36,
                dropdownWidth: `180px`,
                onClick: () => onPressDropdown('typeDementia'),
                onSelectSort: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`typeDementia`, ``) : onSelectDataDropdown(`typeDementia`, name)
                },
                value: typeDementia.value,
                placeholder: typeDementia.placeholder,
              })
          }
          {
            showDropdownFilter.gender &&
              renderInputDropdown({
                showDropdown: gender.showDropdown,
                dropdownData: genderData,
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                text: gender.value === ''? 'Gender': gender.value,
                borderColor: gender.value === ''? Colors.color20: Colors.color36,
                dropdownWidth: `108px`,
                onClick: () => onPressDropdown('gender'),
                onSelectSort: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`gender`, ``) : onSelectDataDropdown(`gender`, name)
                },
                value: gender.value,
                placeholder: gender.placeholder,
              })
          }
          { 
            showDropdownFilter.language &&
              renderInputDropdown({
                showDropdown: language.showDropdown,
                dropdownData: languageData,
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                borderColor: language.value === ''? Colors.color20: Colors.color36,
                text: language.value === ''? 'Preferred Language': language.value,
                dropdownWidth: `120px`,
                onClick: () => onPressDropdown('language')
                ,
                onSelectSort: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`language`, ``) : onSelectDataDropdown(`language`, name)
                },
                value: language.value,
                placeholder: language.placeholder,
              })     
          }
          </div>
      </StyledFilterUser>
  )
}

DialogFilterUser.propTypes = {
  dialogProps: PropTypes.any,
  checkboxData: PropTypes.any,
  onSelectCheckbox: PropTypes.func,
  onSelectDropdown: PropTypes.func,
  onApply: PropTypes.func,
  settedFilterBy: PropTypes.any,
  additionalFilter: PropTypes.any,
  onCheckFilter: PropTypes.func,
  onSetAdditionalFilter: PropTypes.func,
  showDropdownFilter: {
    typeDementia: true,
    gender: true,
    language: true,
  }
}

DialogFilterUser.defaultProps = {
  dialogProps: () => null,
  checkboxData: checkboxDefaultData,
  onSelectCheckbox: () => null,
  onSelectDropdown: () => null,
  onApply: () => null,
  settedFilterBy: [],
  additionalFilter: {},
  onCheckFilter: () => null,
  onSetAdditionalFilter: () => null,
  showDropdownFilter: {
    typeDementia: true,
    gender: true,
    language: true,
  }
}

export default DialogFilterUser

// inputProps={{
//     placeholder: name,
//     readOnly: true,
//     onClick: () => {
//         console.log(value)
//         valFilter = filterBy.filter(el=> el.name === value.name).length
//         // console.log(filterBy)
//         console.log(valFilter)
//         if (valFilter.length <= 0){
//             setFilterBy([...filterBy,{ name: value.name, value: value.data.value }])
//             onApply(filterBy, { typeDementia: dropdowns.typeDementia.value, gender: dropdowns.gender.value, language: dropdowns.language.value })
//             console.log(filterBy)
//         }
//         if (valFilter > 0 ) {
//             let newFilter = filterBy.filter(el=> {
//                 el.name != value.name
//             })
//             setFilterBy([...filterBy, ...newFilter])
//             onApply(filterBy, { typeDementia: dropdowns.typeDementia.value, gender: dropdowns.gender.value, language: dropdowns.language.value })
//         }
//     }
//   }}
//   containerStyle={`
//     ${InputTypeStyle}
//   `}
//   iconRight={onCheck}
//   iconRightStyle={{ width: '15px' }}




{/* <Input
          inputProps={{
            readOnly: true,
            onClick: filtered ? onClick : null,
            value,
            placeholder,
          }}
          containerStyle={`
            ${InputReadOnlyStyle}
            .input-field {
              cursor: ${filtered ? 'pointer' : 'not-allowed'}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={Images.polygonYellow}
          {...props}
          dropdownWidth={'100%'}
        /> */}
