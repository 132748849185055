import React, { Component, createRef} from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, } from 'react-router-dom'
import memoize from "memoize-one";
import moment from 'moment'
import AppConfig from '../config.js';
import {getAllReport, getCountReport, downloadPdf, EditReport} from '../api/AdminAPI';

import { StyledReportMissing, ButtonSearch, SearchBarStyle, ButtonOpen, ButtonClosed, inputText, inputNotes, ButtonSaveNotes, } from './styles/StyleReportMissing'

import Button from '../components/button'
import Table from '../components/table'
import Dialog from '../components/modal'
import DialogInfo from '../components/dialog-info'
import Input from '../components/input'
import Pagination from '../components/pagination'
import PDFFile from '../components/pdf';

import { Colors, Images, } from '../themes'

import ProccessData from '../lib/ProccessData'

import ReportActions from '../redux/ReportMissingRedux';

import { deleteNotification } from '../api/NotificationAPI'
import { USER_TYPE } from '../constants';

const ref = React.createRef();
const dropdownData = [
  {
    id: 1,
    name: '24 Hours',
    sortBy: '24',
  },
  {
    id: 2,
    name: '18 Hours',
    sortBy: '18',
  },
  {
    id: 3,
    name: '12 Hours',
    sortBy: '12',
  },
  {
    id: 4,
    name: '6 Hours',
    sortBy: '6',
  },
  {
    id: 5,
    name: 'none',
  }
]

class ReportMissing extends Component {
  constructor(props) {
    super(props)
    const {reportList} = this.props
    this.state = {
      loading: false,
      dialogConfirmation: false,
      page: 0,
      listOpen: true,
      sortDropdown: false,
      sortBy: {},
      searchFilter: '',
      searchMax: AppConfig.DEFAULT_LIMIT_USER,
      pageSize: 10,
      reportsData: [],
      reportDetail: null,
      dataImages: [],
      filterBy: [],
      notes: '',
      countReport: {},
      copied: false,
      openPage: (reportList.data && reportList.data.params) ? reportList.data.params.searchPage : 1,
      closePage: (reportList.data && reportList.data.params) ? reportList.data.params.searchPage : 1,
      showOpen: false,
      showClose: false,
      isDownload: false,
      countSearch: 0,
      searchPage: 1,
      successOpen: false,
      successClose: false,
      hasOpenReport: false,
    }
  }
  
  componentDidMount() {
    this.setState({ loading: true })

    this.getReports()
    this.getCounReport()
  }

  getCounReport = async (params) => {
    try {
      const {data} = await getCountReport()

      this.setState({
        countReport: data
      })
    } catch(e) {
      console.log(e)
    }
  }
  
  getReports = async (params) => {
    const {filterBy, searchFilter, pageSize, listOpen, sortBy, closePage, openPage} = this.state
    try {
      let dataParams = {
        status: params && (params.status || !params.status) ? params.status : listOpen,
        limit: pageSize,
        sortBy: params && params.sortBy,
        // searchPage: params && params.searchPage && filterBy.length === 0 && searchFilter === '' ? params.searchPage : 1,
        searchPage: params && params.searchPage ? params.searchPage : 1,
        searchMax: 10,
      }
      if (filterBy.length > 0) {
        dataParams.filterBy = filterBy
      }
      if (searchFilter !== '') {
        dataParams.search = searchFilter
      }
      const {data} = await getAllReport(dataParams)

      this.setState({
        loading: false,
        reportsData: data.listReport,
        countSearch: data.countSearch,
        searchPage: data.searchPage,
      })
    } catch (e) {
      console.log(e)
    }
  }

  handleUpdate = pageIndex => {
    this.setState({ page: pageIndex });
  };

  handlePagination = async (Index)=> {
    const {listOpen, openPage, closePage, sortBy} = this.state
    if (listOpen) {
      this.setState({openPage: Index, loading: true})
    } else {
      this.setState({closePage: Index, loading: true})
    }

    this.getReports({
      searchPage: Index ? Index : 1,
      status: listOpen,
      sortBy: sortBy !== {} ? sortBy.sortBy : {}
    })
  };

  onCheckFilter(value) {
    if (this.state.filterBy == value) {
      this.setState({filterBy: []})
    } else {
      this.setState({ filterBy: value, })
    }

  }

  generatePdf = async () => {
    const {reportDetail} = this.state
    try {
      let resultExport = {}
        resultExport = await downloadPdf({id: reportDetail.id})
        let blob = new Blob([resultExport], {
          type: ''
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ReportMissing.pdf`);
        document.body.appendChild(link);
        link.click();
        this.setState({isDownload: false})
    } catch (e) {
      console.log('err catch export ', e)
    }
  }

  openCloseReport = async (status) => {
    const {searchPage, listOpen, sortBy} = this.state

    const currentPage = searchPage ? searchPage : 1
    try {
      let dataParams = {
        id: this.state.reportDetail.id,
        status: status,
      }

      if (status) {
        dataParams.plwdId = this.state.reportDetail.plwdId
      }

      const {data} = await EditReport(dataParams)
      this.getReports({searchPage: currentPage, status: listOpen, sortBy: sortBy})
      this.setState({
        showOpen: false, 
        showClose: false,
        successOpen: data && data.length > 0 && status ? true : false,
        successClose: data && data.length > 0 && !status ? true : false,
        hasOpenReport: !data ? true : false,
      })

    } catch (e) {
      throw e
    }
  }

  render() {
    const { reportsData, loading, dialogConfirmation, listOpen, sortBy, sortDropdown, reportDetail, filterBy, notes, countReport, showOpen, showClose, isDownload, countSearch, searchPage, successClose, successOpen, hasOpenReport } = this.state
    var dayName =['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let desc = showOpen ? "Are you sure want to re-open this report?" : "Are you sure want to close this report?"
    let descriptionConfirm = hasOpenReport ? "Sorry, you can't re-open this report because there are still open reports for this PLWD" : successClose ? "Successfully close report" : "Successfully re-open report"
    let totalPages = []
    if (countSearch) {
      for (let i = 1; i <= Math.ceil(countSearch/10); i++) {
        totalPages.push(i)
      }
    } 
    
    const currentPage = searchPage ? searchPage : 1

    const onClickColumn = (props) => {
      let dataImage = []
      if (props.original && props.original.images && props.original.images.length > 0) {
        props.original.images.map((imageItem, index) => {
          dataImage.push(imageItem.imageLink)
        })
      }
      this.setState({dialogConfirmation: true, reportDetail: props.original, dataImages: dataImage, notes: props.original.notes}) 
    }
    const columnsOpen = [
      {
        Cell: props => <img src={!listOpen ? Images.checkActive : Images.checkInactive} style={{width: 20, height: 20, objectFit: 'contain'}}/>, 
        headerClassName: 'table-header header-no',
        className: 'column',
        width: 30,    
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0', whiteSpace: 'pre-line !important', wordWrap: 'break-word' }}>Report No</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.id}</span>, 
        headerClassName: 'table-header header-no',
        className: 'column text-no',
        width: 90,    
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>PLWD Name</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{ props.original && !props.original.detailPlwd ? '' : props.original && props.original.detailPlwd && (props.original.detailPlwd.displayName ? props.original.detailPlwd.displayName : props.original.detailPlwd.lastname ? `${props.original.detailPlwd.firstname} ${props.original.detailPlwd.lastname}` : props.original.detailPlwd.lastname)}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 150,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Submitted by (CG Name/Relationship)</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{`${props.original && !props.original.detailCg ? '' : props.original && props.original.detailCg && props.original.detailCg.lastname ? `${props.original.detailCg.firstname} ${props.original.detailCg.lastname}`: `${props.original.detailCg.firstname}`} ${props.original.relationship && props.original.relationship.relationship ? `/ ${props.original.relationship.relationship}` : ''}`}</span>, 
        headerClassName: 'table-header',
        className: 'column',

      },
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Report Date</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.createdAt ? moment (props.original.createdAt).format('MM/DD/YYYY') : '-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 120,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Report Time</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.createdAt ? moment(props.original.createdAt).format('hh:mm A'): '-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 180,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>No. of hours missing</span>,
        Cell: props => {
          const msInHour = 1000 * 60 * 60;
          const dateNow = new Date().getTime()
          const dateMissing = new Date(props.original.lastSeenDatetime).getTime()
          const hourMissing = Math.round(Math.abs(dateMissing - dateNow)/msInHour)
          const days = Math.floor(hourMissing / 24);
          const remainingHours = hourMissing % 24;
          const formattedResult = `${days}d ${remainingHours}hrs`;
          return (
            <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.lastSeenDatetime !== null ? formattedResult : '-'}</span>
          )
        }, 
        headerClassName: 'table-header',
        className: 'column',
        width: 100,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Last seen location</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.lastSeenLocation}</span>, 
        headerClassName: 'table-header',
        className: 'column',
      }
      ]

    const columnsClosed = [
      {
        Cell: props => <img src={!listOpen ? Images.checkActive : Images.checkInactive} style={{width: 20, height: 20, objectFit: 'contain'}}/>, 
        headerClassName: 'table-header header-no',
        className: 'column',
        width: 30,    
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0', whiteSpace: 'pre-line !important', wordWrap: 'break-word' }}>Report No</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.id}</span>, 
        headerClassName: 'table-header header-no',
        className: 'column text-no',
        width: 90,    
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>PLWD Name</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{ props.original && !props.original.detailPlwd ? '' : props.original && props.original.detailPlwd && (props.original.detailPlwd.displayName ? props.original.detailPlwd.displayName : props.original.detailPlwd.lastname ? `${props.original.detailPlwd.firstname} ${props.original.detailPlwd.lastname}` : props.original.detailPlwd.lastname)}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 150,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Submitted by (CG Name/Relationship)</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{`${props.original && !props.original.detailCg ? '' : props.original && props.original.detailCg && props.original.detailCg.lastname ? `${props.original.detailCg.firstname} ${props.original.detailCg.lastname}`: `${props.original.detailCg.firstname}`} ${props.original.relationship && props.original.relationship.relationship ? `/ ${props.original.relationship.relationship}` : ''}`}</span>, 
        headerClassName: 'table-header',
        className: 'column',

      },
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Report Date</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.createdAt ? moment (props.original.createdAt).format('MM/DD/YYYY') : '-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 120,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Report Time</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.createdAt ? moment(props.original.createdAt).format('hh:mm A'): '-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 180,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>No. of hours missing</span>,
        Cell: props => {
          const msInHour = 1000 * 60 * 60;
          // const dateNow = new Date().getTime()
          const dateClosed = new Date(props.original.closedDatetime).getTime()
          const dateMissing = new Date(props.original.lastSeenDatetime).getTime()
          const hourMissing = Math.round(Math.abs(dateMissing - dateClosed)/msInHour)
          const days = Math.floor(hourMissing / 24);
          const remainingHours = hourMissing % 24;
          const formattedResult = `${days}d ${remainingHours}hrs`;
          return (
            <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.lastSeenDatetime !== null ? formattedResult : '-'}</span>
          )
        }, 
        headerClassName: 'table-header',
        className: 'column',
        width: 100,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Last seen location</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.lastSeenLocation}</span>, 
        headerClassName: 'table-header',
        className: 'column',
      },
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Closed Date</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.closedDatetime ? moment (props.original.closedDatetime).format('MM/DD/YYYY') : '-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 120,
      }, 
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Closed Time</span>,
        Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.closedDatetime ? moment(props.original.closedDatetime).format('hh:mm A'): '-'}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        width: 180,
      }, 
      ]

    return (
      <StyledReportMissing>
        <Dialog
          isVisible={dialogConfirmation}
          isClose={() => {
            this.setState({ dialogConfirmation: false, copied: false, notes: ''})
            this.getReports({searchPage: currentPage, status: listOpen, sortBy: sortBy})
          }}
          showXClose={true}
          additionalStyle={{ titleStyle: ` color: ${Colors.color13} !important; ` }}
          width={`45%`}
          // cancelText={`Re-Open`}
          okText={listOpen ? `Close` : `Re-Open`}
          okTextColor={Colors.color3}
          // cancelTextColor={Colors.color3}
          onOk={() => {
            listOpen ?
            this.setState({showClose: true, dialogConfirmation: false})
            :
            this.setState({showOpen: true, dialogConfirmation: false})
          }}
          // onCancel={() => this.setState({showOpen: true, dialogConfirmation: false})}
          // cancelBackgroundColor={Colors.color13}
          okBackgroundColor={listOpen ? Colors.color37 : Colors.color13}
          // cancelBordered={false}
          okBordered={false}
        >

          <div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
              <span className='title-dialog'>Report No {reportDetail && reportDetail.id}</span>
              <div className="buttonSeparator" />
              <Button 
                text={isDownload ? "Downloading..." : "+ Download as PDF"}
                buttonStyle={{width: '165px', height: '40px'}}
                bordered={true}
                borderColor={Colors.color11}
                textColor=  {Colors.color11}
                onClick={() => {
                  this.setState({isDownload: true})
                  this.generatePdf()
                }}
              />
              <div className="buttonSeparator" />
              <Button 
                text="Copy Text"
                buttonStyle={{width: '130px', height: '40px'}}
                bordered={true}
                borderColor={Colors.color11}
                textColor=  {Colors.color11}
                onClick={() => {
                  this.setState({copied: true})
                  navigator.clipboard.writeText(`Missing person with details:\nName: ${reportDetail.detailPlwd.displayName}\nAge:  ${Math.floor((new Date() - new Date(reportDetail.detailPlwd.birth).getTime()) / 3.15576e+10)}\nGender: ${reportDetail.detailPlwd.gender}\nOther Physical Trait: ${reportDetail.physicalTrait}\nContact Name: ${reportDetail.detailCg && reportDetail.detailCg.displayName ? reportDetail.detailCg.displayName : '-'}\nContact Person: ${reportDetail.detailCg.phone ? `${reportDetail.detailCg.prefixCountryNum} ${reportDetail.detailCg.phone}`: '-'}\nLast Seen Known Attire: ${reportDetail.lastSeenAttire}\nLast Seen Location: ${reportDetail.lastSeenLocation}\nLast Seen Date: ${dayName[new Date(reportDetail.lastSeenDatetime).getDay()]}, ${moment(reportDetail.lastSeenDatetime).format('DD MMM YYYY')}\nLast Seen Time: ${moment(reportDetail.lastSeenDatetime).format('hh:mm A')}\nPlaces Frequented: ${reportDetail.placesFrequented}\n
                  `)
                }}
              />
            </div>
            {this.state.copied &&
            <div style={{paddingTop: 15}}>
              <span className='label-copied'>Details have been copied to your clipboard</span>
            </div>
            }
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20}}>
              <div style={{width: "43%"}}>
              <span className='label-dialog'>Name</span>
              <Input
                containerStyle={inputText}
                inputProps={{
                  value: reportDetail && reportDetail.detailPlwd && (reportDetail.detailPlwd.displayName ? reportDetail.detailPlwd.displayName : reportDetail.detailPlwd.lastname ? `${reportDetail.detailPlwd.firstname} ${reportDetail.detailPlwd.lastname}` : reportDetail.detailPlwd.firstname)
                }}
              />
              <span className='label-dialog'>Age</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.detailPlwd && Math.floor((new Date() - new Date(reportDetail.detailPlwd.birth).getTime()) / 3.15576e+10)
                }}
              />
              <span className='label-dialog'>Gender</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.detailPlwd && reportDetail.detailPlwd.gender
                }}
              />
              <span className='label-dialog'>Other Physical Trait</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.physicalTrait
                }}
              />
              <span className='label-dialog'>Relationship to PLWD</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.relationship && reportDetail.relationship.relationship
                }}
              />
              <span className='label-dialog'>Last Seen Known Attire</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.lastSeenAttire
                }}
              />
              <span className='label-dialog'>Last Seen Location</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.lastSeenLocation,
                }}
              />
              <span className='label-dialog'>Last Seen Date</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.lastSeenDatetime && `${dayName[new Date(reportDetail.lastSeenDatetime).getDay()]}, ${moment(reportDetail.lastSeenDatetime).format('DD MMM YYYY')}` 
                }}
              />
              <span className='label-dialog'>Last Seen Time</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.lastSeenDatetime && moment(reportDetail.lastSeenDatetime).format('hh:mm A')
                }}
              />
              <span className='label-dialog'>Places Frequented</span>
              <Input
                containerStyle={inputText}
                inputProps={{ 
                  value: reportDetail && reportDetail.placesFrequented
                }}
              />
              {/* <span className='label-dialog'>All CG(s) Tagged to PLWD</span>
              {reportDetail && reportDetail.listTagging && reportDetail.listTagging.length > 0 && reportDetail.listTagging.map((cgTag, index) => {
                const displayName = `${cgTag.AllCg[0].firstname} ${cgTag.AllCg[0].lastname ? cgTag.AllCg[0].lastname : ''} `
                return (
                  <Input
                    key={index}
                    containerStyle={inputText}
                    inputProps={{ 
                      value: displayName
                    }}
                  />
                )
              })} */}
              </div>
              <div style={{width: "45%"}}>
                {reportDetail && reportDetail.images.length > 0 &&
                  <img src={reportDetail.images[0].imageLink} style={{width: "100%", height: 300, objectFit: 'cover', borderRadius: 10, marginBottom: 20}} />
                }
                <div>
                <Input
                  containerStyle={inputNotes}
                  inputProps={{ 
                    placeholder: "Notes", 
                    onChange: (e) => this.setState({ notes: e.target.value }),
                    value: notes,
                    multiline: true,
                    rows: 4,
                  }}
                />
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                  <Button 
                    text="Save Notes"
                    buttonStyle={ButtonSaveNotes}
                    backgroundColor={Colors.color14}
                    textColor=  {Colors.color3}
                    onClick={() => {
                      this.props.editReport({id: reportDetail.id, notes: notes})
                      this.getReports({searchPage: currentPage, status: listOpen, sortBy: sortBy})
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          isVisible={showOpen || showClose}
          width={'30%'}
          height={'60%'}
          okText={`Ok`}
          onOk={() => {
            if (showOpen) {
              this.openCloseReport(true)
            } else {
              this.openCloseReport(false)
            }
          }}
          isClose={() => {
            this.setState({copied: false, notes: '', showClose: false, showOpen: false, dialogConfirmation : true})
          }}
          showXClose={true}
          okBackgroundColor={Colors.color8}
          okBordered={false}
          okTextColor={Colors.color3}
        >
          <div>
            <div className='title-Confirm'>{desc}</div>
          </div>
        </Dialog>
        <Dialog
          isVisible={hasOpenReport || successClose || successOpen}
          width={'30%'}
          height={'60%'}
          okText={`Ok`}
          onOk={() => {
            this.setState({
              copied: false, notes: '', dialogConfirmation: false, hasOpenReport: false, successClose: false, successOpen: false
            })
          }}
          showXClose={false}
          okBackgroundColor={Colors.color8}
          okBordered={false}
          okTextColor={Colors.color3}
        >
          <div>
            <div className='title-Confirm'>{descriptionConfirm}</div>
          </div>
        </Dialog>

        <div className="title-container">
          <h1 className="title">Missing Persons Reports</h1>
          {
            (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
              <div style={{backgroundColor: listOpen ? Colors.color13 : Colors.color37, width: 130, borderRadius: 10, height: 50, justifyContent: 'center', padding: '16px 10px'}}>
                <p style={{color: Colors.color3, fontWeight: 800}}>{listOpen ? `OPEN : ${countSearch}` : `CLOSED : ${countSearch}`}</p>
              </div>
            )
          }
        </div>
        <div>
          <p style={{fontWeight: 800, fontSize: 20, color: Colors.color11 }}>{`Total Reports : ${countReport.all}`}</p>
        </div>
        <div className="search-container">
          <Input
            containerStyle={SearchBarStyle}
            inputProps={{ 
              placeholder: "Search", 
              // value: '',
              onChange: (e) => this.setState({ searchFilter: e.target.value })
            }}
          />
          <div className="buttonSeparator" />
          <Button 
            text="Search"
            buttonStyle={ButtonSearch}
            backgroundColor={Colors.color8}
            textColor=  {Colors.color3}
            onClick={() => {
              this.setState({loading: true})
              this.getReports()
            }}
          />
          <div className="buttonSeparator" />
          <Button 
            text={sortBy && sortBy.name ? sortBy.name : "Sort By"}
            bordered
            textColor={Colors.color11}
            buttonStyle={` font-weight: bold; width: 106px; height: 40px; display: flex; justify-content: space-around; `}
            iconRight
            dropdownData={dropdownData}
            onClick={() => this.setState({ sortDropdown: sortDropdown === true ? false : true })}
            showDropdown={sortDropdown}
            onSelectSort={(data) => {
              this.setState({ sortDropdown: false, sortBy: data && data.id == 5 ? {} : data })
              this.getReports({sortBy: data.sortBy, status: listOpen})
            }}
            selectedData={sortBy.name}
            styleHoverDropdown={{backgroundColor: Colors.color14}}
            dropdowStyle={{borderRadius: 10}}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: "82.5%", height: 60, marginTop: 20, backgroundColor: Colors.color3, alignItems: 'center', padding: '10px', borderRadius: 10}}>
          <Button
            text={"PLWD name"}    
            textColor={Colors.color2}
            borderColor={ filterBy.indexOf("plwdName") > -1 ? Colors.color36 : Colors.color20 }
            bordered
            iconRight={true}
            iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
            buttonStyle={` font-weight: bold; max-width: 170px; height: 40px; display: flex; margin: 0 10px 0 0; padding: 0 10px `}
            backgroundColor={'transparent'}
            iconRightImage={ filterBy.indexOf("plwdName") > -1 ? Images.checkActive : Images.checkInactive}
            onClick={() => this.onCheckFilter("plwdName")}
          />
          <div className="buttonSeparator" />
          <Button
            text={"Caregiver name"}    
            textColor={Colors.color2}
            borderColor={ filterBy.indexOf("CaregiverName") > -1 ? Colors.color36 : Colors.color20 }
            bordered
            iconRight={true}
            iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
            buttonStyle={` font-weight: bold; max-width: 170px; height: 40px; display: flex; margin: 0 10px 0 0; padding: 0 10px `}
            backgroundColor={'transparent'}
            iconRightImage={ filterBy.indexOf("CaregiverName") > -1 ? Images.checkActive : Images.checkInactive}
            onClick={() => this.onCheckFilter("CaregiverName")}
          />
          <div className="buttonSeparator" />
          <Button
            text={"Last 4 digit NRIC"}    
            textColor={Colors.color2}
            borderColor={ filterBy.indexOf("lastNRIC") > -1 ? Colors.color36 : Colors.color20 }
            bordered
            iconRight={true}
            iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
            buttonStyle={` font-weight: bold; max-width: 170px; height: 40px; display: flex; margin: 0 10px 0 0; padding: 0 10px `}
            backgroundColor={'transparent'}
            iconRightImage={ filterBy.indexOf("lastNRIC") > -1 ? Images.checkActive : Images.checkInactive}
            onClick={() => this.onCheckFilter("lastNRIC")}
          />
          <div className="buttonSeparator" />
          <Button
            text={"Report no"}    
            textColor={Colors.color2}
            borderColor={ filterBy.indexOf("ReportNo") > -1 ? Colors.color36 : Colors.color20 }
            bordered
            iconRight={true}
            iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
            buttonStyle={` font-weight: bold; max-width: 170px; height: 40px; display: flex; margin: 0 10px 0 0; padding: 0 10px `}
            backgroundColor={'transparent'}
            iconRightImage={ filterBy.indexOf("ReportNo") > -1 ? Images.checkActive : Images.checkInactive}
            onClick={() => this.onCheckFilter("ReportNo")}
          />
          <div className="buttonSeparator" />
          <Button
            text={"Missing Date"}    
            textColor={Colors.color2}
            borderColor={ filterBy.indexOf("MissingDate") > -1 ? Colors.color36 : Colors.color20 }
            bordered
            iconRight={true}
            iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
            buttonStyle={` font-weight: bold; max-width: 170px; height: 40px; display: flex; margin: 0 10px 0 0; padding: 0 10px `}
            backgroundColor={'transparent'}
            iconRightImage={ filterBy.indexOf("MissingDate") > -1 ? Images.checkActive : Images.checkInactive}
            onClick={() => this.onCheckFilter("MissingDate")}
          />
        </div>
        <div style={{display: 'flex', flexDireaction: 'row', marginTop: 20}}>
          <Button 
            text="Open"
            buttonStyle={ButtonOpen}
            backgroundColor={Colors.color3}
            textColor={listOpen ? Colors.color11 : Colors.color7}
            onClick={() => {
              if (!listOpen) {
                this.setState({listOpen: true, loading: true, searchPage: 1})
                this.getReports({status: true, sortBy: sortBy !== {} ? sortBy.sortBy : {}, searchPage: 1})
              }
            }}
            bordered={listOpen ? true : false}
            borderColor={Colors.color11}
          />
          <Button 
            text="Closed"
            buttonStyle={ButtonClosed}
            backgroundColor={Colors.color3}
            textColor={listOpen ? Colors.color7 : Colors.color11}
            onClick={() => {
              if (listOpen) {
                this.setState({listOpen: false, loading: true})
                this.getReports({status: false,  sortBy: sortBy !== {} ? sortBy.sortBy : {}})
              }
            }}
            bordered={listOpen ? false : true}
            borderColor={Colors.color11}
          />
        </div>
        <Table 
          columns={listOpen ? columnsOpen : columnsClosed}
          data={reportsData}
          loading={loading}
          pageSize={10}
          dataLimit={AppConfig.DEFAULT_LIMIT_USER}
          tableProps={{ showPageSizeOptions: false, showPagination: false,}}
        />
        <Pagination
          page={currentPage}
          onClickPrevious={() => this.handlePagination(parseInt(currentPage) - 1)}
          onClickNext={() => this.handlePagination(parseInt(currentPage) + 1)}
          onClickPage={(number) => this.handlePagination(number)}
          totalPage={totalPages}
        />
      </StyledReportMissing>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.auth.account,
    reportList: state.reportMissing.reportMissingList,
    listEditReport: state.reportMissing.editReport,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: (params) => dispatch(ReportActions.getReportMissingRequest(params)),
    editReport: (params) => dispatch(ReportActions.editReportMissingRequest(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportMissing);
