var defaultConfig = {
  REACT_APP_HOSTNAME: "https://cara-staging.embreo.global",
  REACT_APP_ACCOUNT_URL: "http://localhost:9000/rest",
  REACT_APP_BENEFITS_URL: "https://cara-api-staging.embreo.global/benefits",
  REACT_APP_SOLUTIONS_URL: "https://cara-api-staging.embreo.global/solutions",
  REACT_APP_NOTIFICATIONS_URL: "https://cara-api-staging.embreo.global/notifications",
  REACT_APP_SENTRY_URL: "https://8b9ec62c837642909e7a23a4e4f3ad29@o1343547.ingest.sentry.io/4504094163337216",

  //Azure
  CLIENT_ID: "3c2ad91f-b147-4e37-8911-b05e80f223ef",
};

//overwrite defaultConfig from env
var config = {
  development: {
    // nothing to overwrite
    REACT_APP_ACCOUNT_URL: "https://cara-api-staging.embreo.global/accounts/rest",
  },
  staging: {
    REACT_APP_ACCOUNT_URL: "https://cara-api-staging.embreo.global/accounts/rest",
  },
  production: {
    REACT_APP_HOSTNAME: "https://web.cara.sg",
    REACT_APP_ACCOUNT_URL: "https://api.cara.sg/accounts/rest",
    REACT_APP_BENEFITS_URL: "https://api.cara.sg/benefits",
    REACT_APP_SOLUTIONS_URL: "https://api.cara.sg/solutions",
    REACT_APP_NOTIFICATIONS_URL: "https://api.cara.sg/notifications",
    
    //Azure
    CLIENT_ID: "c1a3604e-caef-49bc-8b2f-a8f481ca6707",
  },
};

const env = process.env.REACT_APP_ENV || 'development';
console.log('Environment =', env);

//overwrite defaultConfig from env
const envConfig = config[env];
Object.keys(envConfig).forEach((key) => {
  defaultConfig[key] = envConfig[key];
});

// Overwrite process.env
console.log(defaultConfig);
Object.keys(defaultConfig).forEach((key) => {
  if (!process.env[key]) {
    process.env[key] = defaultConfig[key];
  }
});


//migrate from config/AppConfig.js
export default {
  ACCOUNT_URL: defaultConfig['REACT_APP_ACCOUNT_URL'],
  APP_NAME: 'ada-admin',
  BENEFITS_URL: defaultConfig['REACT_APP_BENEFITS_URL'],
  SOLUTIONS_URL: defaultConfig['REACT_APP_SOLUTIONS_URL'],
  NOTIFICATIONS_URL: defaultConfig['REACT_APP_NOTIFICATIONS_URL'],
  DEFAULT_LIMIT: 12,
  HOSTNAME: defaultConfig['REACT_APP_HOSTNAME'],
  DEFAULT_LIMIT_USER: 10,
  SENTRY_URL: defaultConfig['REACT_APP_SENTRY_URL'],
  CLIENT_ID: defaultConfig['CLIENT_ID'],
};

