import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { XYPlot, LineSeries, RadialChart, DiscreteColorLegend, } from 'react-vis';
import Swal from 'sweetalert2'

import { StyledAnalytics } from './styles/StyledAnalytics'
import { Typography, Images, Colors, } from '../themes'

import { getCountSignupPlatform, } from '../api/UserAPI'

import ProccessData from '../lib/ProccessData'

const { Title, SubTitle } = Typography

const ITEMS = [
  {
    id: 1,
    platform: 'Web',
    total: 0,
  },
  {
    id: 1,
    platform: 'Mobile',
    total: 0,
  },
];

const COLORS = [
  Colors.color36,
  Colors.color25,
];

export class Analytics extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hoveredItem: false,
      loading: false,
      totalUsers: 0,
      legendData: ITEMS,
      chartData: [],
    }
  }

  componentDidMount() {
    this.getApi({  })
  }

  getApi = async ({ params = {}, }) => {
    this.setState({ loading: true, })
    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = await getCountSignupPlatform(params)
      ProccessData(resultGet, 'get total user')
      .then(res => {
        const { mobile, mobilePercentage, website, webPercentage, totalUsers } = res.data
        const chartData = []

        if (website > 0) chartData.push({
          angle: website,
          label: webPercentage,
          className: 'mobile-value',
          color: Colors.color36,
        })

        if (mobile > 0) chartData.push({
          color: Colors.color25,
          angle: mobile,
          label: mobilePercentage,
          className: 'web-value',
        })

        this.setState({ 
          loading: false,
          legendData: [
            {
              ...ITEMS[0],
              total: website,
            },
            {
              ...ITEMS[1],
              total: mobile,
            },
          ],
          chartData,
          totalUsers,
        })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err,
        })
        this.setState({ loading: false, })
      })
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e,
      })
      this.setState({ loading: false, })
    }
  }


  render() {
    const { hoveredItem, legendData, loading, chartData, totalUsers, } = this.state
    return (
      <StyledAnalytics>
        <div className="container">
          <div className="header">
            <Title customStyle={` color: black; `}>Analytics</Title>
          </div>
          <div>
            <p className="coming-soon-text" style={{ marginTop: 0, marginBottom: '20px', }}>User Registration Platform</p>
            {
              loading ?
                <p>Loading...</p>
              : 
              <>
                <DiscreteColorLegend
                  colors={COLORS}
                  onItemMouseEnter={i => this.setState({ hoveredItem: i })}
                  onItemMouseLeave={() => this.setState({ hoveredItem: false })}
                  orientation="horizontal"
                  width={400}
                  height={60}
                  items={legendData.map(
                    (item, key) => 
                      hoveredItem === item.platform ? (
                        <div key={key}>
                          {item.platform}
                          <br />
                          {`${item.total} users`}
                        </div>
                      ) : (
                        item.platform
                      )
                      
                  )}
                />
                <div className="radial-chart-container">
                  <RadialChart
                    data={chartData}
                    width={300}
                    height={300}
                    animation
                    showLabels
                    colorType="literal"
                    labelsStyle={{
                      fontFamily: `NunitoSans`,
                      fontSize: `18px`
                    }}
                  />
                </div>
                <p className="total-text">Total: {totalUsers} users</p>
              </>
            }
            {/* <div className="coming-soon-container">
              <img src={Images.analyticsComingSoon} style={{ width: '350px', height: '330px', objectFit: 'contain' }} />
              <p className="coming-soon-text">Coming Soon-Phase 2</p>
            </div> */}
          </div>
        </div>
      </StyledAnalytics>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
