import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledAnalytics = styled.div`
  min-height: 100vh;
  background-color: ${Colors.primaryBgColor};

  .container {
    width: 100%;
    margin: 0;
    padding-left: 40px;
    padding-right: 74px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 62px 0 25px 0;
  }

  .coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .coming-soon-text {
    ${Fonts.FontRegular}
    ${Fonts.size.size24};
    font-weight: 800;
    color: ${Colors.color19};
    margin-top: 60px;
  }

  .radial-chart-container {
    overflow: hidden;
    width: 100%;
  }

  .mobile-value {
    color: red !important,
  }

  .total-text {
    font-family: NunitoSans;
    ${Fonts.size.size24}
    font-weight: 800;
    color: ${Colors.color2};
    margin-top: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 250px;
    ${props => props.titleStyle}
  }
`