import { API } from '../lib/Request'
// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

const solutionsUrl = AppConfig.SOLUTIONS_URL

export const getTotalSolutions = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${solutionsUrl}/solutions?limit=${data.limit}`, method: 'GET', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getTotalSolutionCategories = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${solutionsUrl}/solution-category?limit=${data.limit}`, method: 'GET', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}