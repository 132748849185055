import React from 'react'
import { EditorState, convertToRaw, ContentState, convertFromRaw,} from 'draft-js';
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import { connect } from 'react-redux';
import memoize from "memoize-one";
import moment from 'moment'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import imageCompression from 'browser-image-compression'

import { Typography, Colors, } from '../themes'
import Card from "../components/card";
import Dialog from "../components/dialogBenefit"
import Button from '../components/button'
import DialogAddCategory from "../components/dialogBenefit/DialogAddCategory"
import DialogInfo from '../components/dialog-info'
import DialogPopUp from "../components/modal"

import SolutionsActions from '../redux/SolutionsRedux'

import { StyledSolution } from './styles/StyledSolution'

import ProccessData from '../lib/ProccessData'

import { getTotalSolutions, getTotalSolutionCategories } from '../api/SolutionsAPI'
import { addNotification, deleteNotificationByQuery } from '../api/NotificationAPI';

// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'
import { USER_TYPE } from '../constants';

const { Title, SubTitle } = Typography

const defaultState = {
  // for solutions
  title: '',
  titleChinese: '',
  descriptions: EditorState.createEmpty(),
  descriptionsChinese: EditorState.createEmpty(),
  vendorName: '',
  vendorAddress: '',
  vendorContactNumber: '',
  vendorWebsite: '',
  vendorNameChinese: '', 
  vendorAddressChinese: '', 
  vendorContactNumberChinese: '', 
  vendorWebsiteChinese: '', 
  listingCategory: '',
  images: [],
  newImages: [],
  imageIndex: null,
  tags: [],
  tagsChinese: [],
  loading: false,
  selectedCategory: null,
  sendNotification: false,
  dialogPopUp: {
    show: false,
    title: 'Confirm Listing',
    content: 'Are you sure want to create this listing?',
    content2: 'Any empty information will only appear in English.',
    okText: 'Yes, Confirm',
    cancelText: 'Cancel',
    state: 'listing'
  }
}

const defaultCategoryState = {
  dialogCategory: false,
  categoryTitle: '',
  categoryTitleChinese: '',
  categoryImage: null,
  categoryImageChinese: null,
  categoryNewImage: null,
  categoryNewImageChinese: null,
}

class Solution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      solutionsData: [],
      loading: false,
      show: 'SOLUTIONS',
      categories: [],
      sortDropdown: false,
      loadingPagination: true,
      totalData: 0,
      sortBy: {},
      ...defaultState,
      ...defaultCategoryState,
      infoDialogShow: false,
      infoDescription: ''
    }

    this.onDelete = this.onDelete.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
    this.onDiscard = this.onDiscard.bind(this)

    this.add = false
    this.delete = false
    this.addCategory = false
    this.deleteCategory = false
    this.page = 1
    this.pageCategories = 1
  }

  htmltoText = (html) => {
    let text = html;
    text = text.replace(/\n/gi, "");
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
    text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
    text = text.replace(/<\/div>/gi, "\n\n");
    text = text.replace(/<\/li>/gi, "\n");
    text = text.replace(/<li.*?>/gi, "  *  ");
    text = text.replace(/<\/ul>/gi, "\n\n");
    text = text.replace(/<\/p>/gi, "\n\n");
    text = text.replace(/<br\s*[\/]?>/gi, "\n");
    text = text.replace(/<[^>]+>/gi, "");
    text = text.replace(/^\s*/gim, "");
    text = text.replace(/ ,/gi, ",");
    text = text.replace(/ +/gi, " ");
    text = text.replace(/\n+/gi, "\n\n");
    // this is for remove &nbsp; 
    text = text.replace(/&nbsp;/g, '');
    return text;
  };

  componentDidMount() {
    this.setState({ loading: true })
    this.props.getSolutions({ data: { page: this.page, limit: AppConfig.DEFAULT_LIMIT } })
    this.props.getSolutionCategories({ data: { page: this.pageCategories, limit: 12 } })
    this.getTotalSolutions()
    this.getTotalSolutionCategories()
  }

  componentWillUnmount() {
    localStorage.setItem("solutionPage", 1)
  }

  onDelete(id) {
    //function props to delete

    const confirmation = confirm(this.state.show === 'SOLUTIONS' ? 'Are you sure want to delete this solution?' : 'Are you sure want to delete this category?')

    if (confirmation) {
      this.setState({ loading: true })
      if (this.state.show === 'SOLUTIONS') {
        this.props.deleteSolution({ id, })
        deleteNotificationByQuery({ name: `SOLUTION_${id}` })
        .then(res => console.log('delete solution notification success ', res))
        this.delete = true
      } else {
        this.props.deleteCategory({ id, })
        this.deleteCategory = true
      }
    } 
  }

  openModal() {
    const dialog = this.state.show === 'SOLUTIONS' ? 'dialog' : 'dialogCategory'
    this.setState({
      [dialog]: true
    })
  }

  closeModal() {
    const dialog = this.state.show === 'SOLUTIONS' ? 'dialog' : 'dialogCategory'
    this.setState({
      [dialog]: false
    })
  }

  setData = memoize((data) => {
    ProccessData(data, 'get solutions')
    .then(res => {
      this.setState({
        loading: false,
        errorText: '',
        solutionsData: res.payload.data,
      })
    })
    .catch(err => {
      this.onDialogInfo(err.message)
      this.setState({
        errorText: err ? err : 'Something wrong, please check your internet connection',
        loading: false,
      })
    })
  })

  setCategoriesData = memoize(data => {
    ProccessData(data, 'get solutions category')
    .then(res => {
      this.setState({
        loading: false,
        errorText: '',
        categories: res.payload.data,
      })
    })
    .catch(err => {
      this.onDialogInfo(err.message)
      this.setState({
        errorText: err ? err : 'Something wrong, please check your internet connection',
        loading: false,
      })
    })
  })

  getAfterDelete = memoize((data) => {
    if (this.delete) {
      ProccessData(data, 'delete solution')
      .then(res => {
        this.props.getSolutions({ data: {} })
        this.onDialogInfo('Data deleted')
        this.delete = false
      })
      .catch(err => {
        this.onDialogInfo(err.message)
        this.setState({
          errorText: err ? err : 'Something wrong, please check your internet connection',
          loading: false,
        })
        this.delete = false
      })
    }
  })

  addResult = memoize(data => {
    const { fetching, payload, error, } = data
    if (this.add) {
      ProccessData(data, 'add solution')
      .then(async res => {
        const solution = res.payload.data[0]
        if (this.state.sendNotification) {   
          const data = {
            title: solution.title,
            text: `${this.htmltoText(solution.SolutionContent.content).substring(0, 50)}...`,
            finishedPicture: solution.SolutionImages[0].imageUrl,
            name: `SOLUTION_${solution.id}`,
            platform: 'Android, iOS',
            type: 'SOLUTIONS',
            delivery: `NOW`,
            frequency: null,
            dateSend: moment(new Date()),
            accountType: 'ALL',
            age: 'ALL',
            typeDementia: 'ALL',
            language: 'ALL',
            gender: 'ALL',
            stageDementia: 'ALL',
            dataNotification: {
              targetScreen: 'SolutionDetailScreen',
              solutionId: `${solution.id}`,
            }
          };
          let pushNotification = await addNotification(data)
          if (pushNotification.type === 'ERROR') {
            this.onDialogInfo('Failed to send push notification')
          }
        }
        this.setState({
          loading: true,
          errorText: '',
          dialog: false,
          ...defaultState,
        })
        this.props.getSolutions({ data: {} })
        this.onDialogInfo('Data Added')
        this.add = false
      })
      .catch(err => {
        this.onDialogInfo(err.message)
        this.setState({
          errorText: err ? err : 'Something wrong, please check your internet connection',
          loading: false,
          dialog: false,
        })
        this.add = false
      })
    }
  })

  addCategoryResult = memoize(data => {
    if (this.addCategory) {
      ProccessData(data, 'add solution category')
      .then(res => {
        this.setState({
          loading: true,
          errorText: '',
          dialogCategory: false,
          ...defaultCategoryState,
        })
        this.props.getSolutionCategories({ data: {} })
        this.onDialogInfo('Data Added')
        this.addCategory = false
      })
      .catch(err => {
        this.onDialogInfo(err.message)
        this.setState({
          errorText: err ? err : 'Something wrong, please check your internet connection',
          loading: false,
          dialogCategory: false,
          ...defaultCategoryState,
        })
        this.addCategory = false
      })
    }
  })

  getCategoryAfterDelete = memoize(data => {
    if (this.deleteCategory) {
      ProccessData(data, 'delete solution category')
      .then(res => {
        this.props.getSolutionCategories({ data: {} })
        this.onDialogInfo('Data Deleted')
        this.deleteCategory = false
      })
      .catch(err => {
        this.onDialogInfo(err.message)
        this.setState({
          errorText: err ? err : 'Something wrong, please check your internet connection',
          loading: false,
        })
        this.deleteCategory = false
      })
    }
  })

  onAdd = () => {
    const { selectedCategory, id, title, titleChinese, descriptions, descriptionsChinese, vendorName, vendorAddress, vendorContactNumber, vendorWebsite, vendorNameChinese, vendorAddressChinese, vendorContactNumberChinese, vendorWebsiteChinese, newImages, tags, tagsChinese, dialogPopUp } = this.state

    this.setState({ loading: true, dialogPopUp: {...dialogPopUp, show: false}  })

    const content = draftToHtml(convertToRaw(descriptions.getCurrentContent()));
    const contentChinese = draftToHtml(convertToRaw(descriptionsChinese.getCurrentContent()));

    this.props.addSolution({
      id,
      data: {
        categoryId: selectedCategory.id,
        title,
        titleChinese,
        content,
        contentChinese,
        iconUrl: '',
        vendor: {
          name: vendorName,
          address: vendorAddress,
          contactNumber: parseInt(vendorContactNumber),
          website: vendorWebsite,
          nameChinese: vendorNameChinese,
          addressChinese: vendorAddressChinese,
          contactNumberChinese: parseInt(vendorContactNumberChinese),
          websiteChinese: vendorWebsiteChinese,
        },
        images: newImages.length > 0 ? newImages : null,
        tags: tags.map(item => item.text).join('--'),
        tagsChinese: tagsChinese.map(item => item.text).join('--'),
      }
    })

    this.add = true
  }

  onDiscard = () => {
    this.setState(defaultState)
    this.setState(defaultCategoryState)
    this.closeModal()
  }

  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  // onChangeImage = async e => {
  //   const { imageIndex, } = this.state
  //   const imageFile = e.target.files[0]
  
  //   const options = {
  //     maxSizeMB: 0.5,
  //     maxWidthOrHeight: 1920,
  //     useWebWorker: true
  //   }
    
  //   try {
  //     const compressedFile = await imageCompression(imageFile, options);
  //     const reader = new FileReader()
  //     console.log('image ori -> ', imageFile.size)
  //     console.log('compressed file -> ', compressedFile.size)
  //     reader.onloadend = () => {
  //       let newImages = [...this.state.newImages]
  //       let images = [...this.state.images]
  //       let image = images[imageIndex]
  //       let changedImage = {
  //         ...image,
  //         imageUrl: reader.result
  //       }
  
  //       newImages.push({ imageId: image ? image.id : null, order: 1, base64Image: reader.result, })
  //       console.log('new state =? ', imageIndex)
  //       images.splice(imageIndex, 1, changedImage)
  
  //       this.setState({ images, newImages, })
  //     } 
  
  //     reader.readAsDataURL(compressedFile);
  //   } catch (error) {
  //     console.log('error compressing image => ', error);
  //   }
  // };

  onChangeImage = async (e, imageUrlKey) => {
    const { imageIndex, } = this.state
    const imageFile = e.target.files[0]

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const reader = new FileReader()
      reader.onloadend = () => {
        let newImages = [...this.state.newImages]
        let images = [...this.state.images]
        let image = images[imageIndex]
        let changedImage = {
          ...image,
          [imageUrlKey === 'base64ImageChinese' ? 'imageUrlChinese' : 'imageUrl']: reader.result
        }
        
        if(newImages.length === 0) {
          newImages.push({ order: 1, [imageUrlKey]: reader.result})
        } else {
          for(let i=0; i < newImages.length; i++) {
            if(!newImages[i][imageUrlKey]){
              newImages[i][imageUrlKey] = reader.result
              break;
            } else if (i === newImages.length - 1) {
              newImages.push({ order: 1, [imageUrlKey]: reader.result})
              break;
            } else {
              continue;
            }
          }
        }

        images.splice(imageIndex, 1, changedImage)
  
        this.setState({ images, newImages, })
        e.target.value = null;
      } 

      reader.readAsDataURL(compressedFile);
    } catch (error) {
    }

  };
  
  onDeleteImage = (imageData, imageIndex) => {
    let images = [...this.state.images]
    let newImages = [...this.state.newImages]

    images.splice(imageIndex, 1)
    newImages.splice(imageIndex, 1)

    this.setState({ images, newImages, })
  }

  onEditorStateChange = (descriptions) => {
    this.setState({
      descriptions,
    });
  };

  onEditorChineseStateChange = (descriptionsChinese) => {
    this.setState({
      descriptionsChinese,
    });
  };

  onChangeCategoryImage = e => {
    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      this.setState({ categoryImage: reader.result, categoryNewImage: reader.result, })
    } 

    reader.readAsDataURL(file);
  };

  onChangeCategoryImageChinese = e => {
    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      this.setState({ categoryImageChinese: reader.result, categoryNewImageChinese: reader.result, })
    } 

    reader.readAsDataURL(file);
  };

  onAddCategory = () => {
    const { categoryTitle, categoryTitleChinese, categoryNewImage, categoryNewImageChinese, dialogPopUp } = this.state

    this.setState({ loading: true, dialogPopUp: {...dialogPopUp, show: false}  })

    this.props.addCategory({
      data: {
        title: categoryTitle,
        titleChinese: categoryTitleChinese,
        description: '',
        order: 1,
        base64Image: categoryNewImage,
        base64ImageChinese: categoryNewImageChinese
      }
    })

    this.addCategory = true
  }

  onValidationCategory = () => {
    const { categoryTitle, categoryTitleChinese, categoryNewImage, categoryNewImageChinese } = this.state

    if(
      (
        (!categoryTitle && !categoryTitleChinese) || 
        (!categoryNewImage && !categoryNewImageChinese)
      ) || (
        !categoryTitleChinese &&
        !categoryNewImageChinese
      )
    ) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: 'Confirm Category',
          content: 'Are you sure want to create this category?',
          state: 'category'
        }
      })
    } else if (
      !categoryTitle &&
      !categoryNewImage
    ) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: 'Confirm Category',
          content: 'Are you sure want to create this category?',
          content2: 'Any empty information will only appear in Mandarin.',
          state: 'category'
        }
      })
    } else {
      return this.onAddCategory()
    }
  }

  onValidationListing= () => {
    const { selectedCategory, id, title, titleChinese, descriptions, descriptionsChinese, vendorName, vendorAddress, vendorContactNumber, vendorWebsite, vendorNameChinese, vendorAddressChinese, vendorContactNumberChinese, vendorWebsiteChinese, newImages, tags, tagsChinese, dialogPopUp } = this.state
    
    if(
      (
        !selectedCategory || 
        (!title && !titleChinese) || 
        (!descriptions && !descriptionsChinese) || 
        (!vendorName && !vendorNameChinese) || 
        (!vendorWebsite && !vendorWebsiteChinese) || 
        (!vendorContactNumber && !vendorContactNumberChinese ) || 
        !newImages || 
        (!tags && !tagsChinese)
      ) || (
        !titleChinese && 
        !vendorNameChinese && 
        !vendorContactNumberChinese && 
        !vendorWebsiteChinese
      )
    ) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: 'Confirm Listing',
          content: 'Are you sure want to create this listing?',
          state: 'listing',
          content2: 'Any empty information will only appear in English.'
        }
      })
    } else if (
      !title && 
      !vendorName && 
      !vendorContactNumber && 
      !vendorWebsite
    ) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: 'Confirm Listing',
          content: 'Are you sure want to create this listing?',
          state: 'listing',
          content2: 'Any empty information will only appear in Mandarin.'
        }
      })
    } else {
      return this.onAdd()
    }
  }

  getTotalSolutions = async () => {
    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = await getTotalSolutions({ limit: 99999999 })
      ProccessData(resultGet, 'get total data')
      .then(res => {
        this.setState({
          loadingPagination: false,
          totalData: res.payload.data.length
        })
      })
      .catch(err => {
        this.onDialogInfo(err.message)
        this.setState({
          loadingPagination: false
        })
      })
    } catch (e) {
      this.onDialogInfo(e.message)
      this.setState({
        loadingPagination: false
      })
    }
  }

  getTotalSolutionCategories = async () => {
    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = await getTotalSolutionCategories({ limit: 99999999 })
      ProccessData(resultGet, 'get total data')
      .then(res => {
        this.setState({
          loadingPaginationCategories: false,
          totalDataCategories: res.payload.data.length
        })
      })
      .catch(err => {
        this.onDialogInfo(err.message)
        this.setState({
          loadingPaginationCategories: false
        })
      })
    } catch (e) {
      this.onDialogInfo(e.message)
      this.setState({
        loadingPaginationCategories: false
      })
    }
  }

  onPressPreviousPage = () => {
    this.setState({ loading: true, })
    let newPage = parseInt(this.page) - 1
    this.page = newPage
    // localStorage.setItem("solutionPage", newPage)
    // this.props.getSolutions({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT } })
    if (this.state.sortBy) {
      this.props.getSolutions({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT, sortBy: this.state.sortBy.sortBy } })
    } else {
      this.props.getSolutions({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT } })
    }
  }

  onPressNextPage = () => {
    this.setState({ loading: true, })
    let newPage = parseInt(this.page) + 1
    this.page = newPage
    // localStorage.setItem("solutionPage", newPage)
    // this.props.getSolutions({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT } })
    if (this.state.sortBy) {
      this.props.getSolutions({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT, sortBy: this.state.sortBy.sortBy } })
    } else {
      this.props.getSolutions({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT } })
    }
  }

  onPressPreviousPageCategories = () => {
    this.setState({ loading: true, })
    let newPage = parseInt(this.pageCategories) - 1
    this.pageCategories = newPage

    this.props.getSolutionCategories({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT } })
  }

  onPressNextPageCategories = () => {
    this.setState({ loading: true, })
    let newPage = parseInt(this.pageCategories) + 1
    this.pageCategories = newPage

    this.props.getSolutionCategories({ data: { page: newPage , limit: AppConfig.DEFAULT_LIMIT } })
  }

  onPressPage = (page) => {
    this.setState({ loading: true, })
    this.page = page
    // localStorage.setItem("solutionPage", page)
    // this.props.getSolutions({ data: { page , limit: AppConfig.DEFAULT_LIMIT } })
    if (this.state.sortBy) {
      this.props.getSolutions({ data: { page , limit: AppConfig.DEFAULT_LIMIT, sortBy: this.state.sortBy.sortBy } })
    } else {
      this.props.getSolutions({ data: { page , limit: AppConfig.DEFAULT_LIMIT } })
    }
  }

  onPressPageCategories = (page) => {
    this.setState({ loading: true, })
    this.pageCategories = page
    // localStorage.setItem("solutionPageCategories", page)
    // this.props.getSolutions({ data: { page , limit: AppConfig.DEFAULT_LIMIT } })
    this.props.getSolutionCategories({ data: { page , limit: 12 } })
  }

  onDialogInfo = (val) => {
    this.setState({infoDescription: val, infoDialogShow: true})
  }

  render () {
    const { 
      solutionsData, 
      infoDialogShow, 
      infoDescription, 
      loading, 
      sortDropdown, 
      show, 
      categories, 
      title, 
      titleChinese, 
      descriptions, 
      descriptionsChinese,
      vendorName, 
      vendorAddress, 
      vendorContactNumber, 
      vendorWebsite, 
      vendorNameChinese, 
      vendorAddressChinese, 
      vendorContactNumberChinese, 
      vendorWebsiteChinese, 
      images, 
      newImages, 
      imageIndex, 
      listingCategory, 
      selectedCategory, 
      categoryTitle, 
      categoryTitleChinese,
      categoryImage, 
      categoryImageChinese,
      categoryNewImage, 
      categoryNewImageChinese,
      loadingPagination, 
      loadingPaginationCategories,
      totalData, 
      totalDataCategories,
      sortBy, 
      sendNotification, 
      tags,
      tagsChinese,
      dialogPopUp
    } = this.state
    const { solutions, solutionCategories, deleteSolutionResult, deleteCategoryResult, history, } = this.props

    this.setData(solutions)
    this.setCategoriesData(solutionCategories)
    this.getAfterDelete(deleteSolutionResult)
    this.addResult(this.props.addSolutionResult)
    this.addCategoryResult(this.props.addCategoryResult)
    this.getCategoryAfterDelete(deleteCategoryResult)
    
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalData / AppConfig.DEFAULT_LIMIT); i++) {
      pageNumbers.push(i);
    }
    
    const pageNumberCategories = [];
    for (let i = 1; i <= Math.ceil(totalDataCategories / AppConfig.DEFAULT_LIMIT); i++) {
      pageNumberCategories.push(i);
    }
    
    return (
      <StyledSolution>
        <Dialog 
          isVisible={this.state.dialog}
          isClose={()=> this.closeModal()}
          onDiscard={()=> this.onDiscard()}
          title="Create New Solution"
          action="ADD"
          benefitTitle={title}
          benefitTitleChinese={titleChinese}
          descriptions={descriptions}
          descriptionsChinese={descriptionsChinese}
          vendorName={vendorName}
          vendorAddress={vendorAddress}
          vendorContactNumber={vendorContactNumber}
          vendorWebsite={vendorWebsite}
          vendorNameChinese={vendorNameChinese}
          vendorAddressChinese={vendorAddressChinese}
          vendorContactNumberChinese={vendorContactNumberChinese}
          vendorWebsiteChinese={vendorWebsiteChinese}
          listingCategory={listingCategory}
          onChangeText={this.onChangeText}
          images={images}
          newImages={newImages}
          onChangeImage={this.onChangeImage}
          onSelectImage={(imageIndex) => this.setState({ imageIndex, })}
          // onSave={this.onAdd}
          onSave={this.onValidationListing}
          loading={loading}
          dropdownData={solutionCategories.payload ? solutionCategories.payload.payload ? solutionCategories.payload.payload.data : [] : []}
          dropdownPropertyName="title"
          onSelectDropdown={(selectedCategory) => this.setState({ selectedCategory, })}
          selectedCategory={selectedCategory}
          onDeleteImage={this.onDeleteImage}
          onEditorStateChange={this.onEditorStateChange}
          onEditorChineseStateChange={this.onEditorChineseStateChange}
          onChangeSendNotification={(sendNotification) => this.setState({ sendNotification })}
          inputTagsProps={{
            tagsData: tags,
            onAddTag: (tag) => {
              this.setState(state => ({ tags: [...state.tags, tag] }));
            },
            onDeleteTag: (i) => {
              this.setState({
                tags: tags.filter((tag, index) => index !== i),
              });
            },
            onDragTag: (tag, currPos, newPos) => {
              const tags = [...this.state.tags];
              const newTags = tags.slice();

              newTags.splice(currPos, 1);
              newTags.splice(newPos, 0, tag);

              // re-render
              this.setState({ tags: newTags });
            },
            onClearData: () => this.setState({ dialog: false, ...defaultState, })
          }}
          inputTagsChineseProps={{
            tagsData: tagsChinese,
            onAddTag: (tag) => {
              this.setState(state => ({ tagsChinese: [...state.tagsChinese, tag] }));
            },
            onDeleteTag: (i) => {
              this.setState({
                tagsChinese: tags.filter((tag, index) => index !== i),
              });
            },
            onDragTag: (tag, currPos, newPos) => {
              const tags = [...this.state.tags];
              const newTags = tags.slice();

              newTags.splice(currPos, 1);
              newTags.splice(newPos, 0, tag);

              // re-render
              this.setState({ tagsChinese: newTags });
            },
            onClearData: () => this.setState({ dialog: false, ...defaultState, })
          }}
        />
        <DialogAddCategory 
          isVisible={this.state.dialogCategory}
          action="ADD"
          dialogTitle="Create New Category"
          isClose={()=> this.closeModal()}
          onDiscard={()=> this.onDiscard()}
          title={categoryTitle}
          titleChinese={categoryTitleChinese}
          stateTitle={'categoryTitle'}
          stateTitleChinese={'categoryTitleChinese'}
          image={categoryImage}
          imageChinese={categoryImageChinese}
          loading={loading}
          onChangeText={this.onChangeText}
          onChangeImage={this.onChangeCategoryImage}
          onChangeImageChinese={this.onChangeCategoryImageChinese}
          // onSave={this.onAddCategory}
          onSave={this.onValidationCategory}
        />
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        <DialogPopUp
          isVisible={dialogPopUp.show}
          okText={dialogPopUp.okText}
          cancelText={dialogPopUp.cancelText}
          onOk={dialogPopUp.state === 'category' ? this.onAddCategory : dialogPopUp.state === 'listing' ? this.onAdd : '' }
          onCancel={()=>this.setState({dialogPopUp: {...dialogPopUp, show: false}})}
          isClose={()=>this.setState({dialogPopUp: {...dialogPopUp, show: false}})}
          height={'250px'}
          okBackgroundColor={Colors.color42}
          okTextColor={'white'}
        >
          <p style={{ color: Colors.color19, fontSize: '26px', fontWeight: '800' }}>{dialogPopUp.title}</p>
          <p style={{ color: Colors.color2, fontSize: '17px', fontWeight: '700', marginTop: '30px' }}>{dialogPopUp.content2}</p>
          <p style={{ color: Colors.color2, fontSize: '17px', fontWeight: '700', marginTop: '10px' }}>{dialogPopUp.content}</p>
        </DialogPopUp>
        <div className="container">
          <div className="header">
            <Title customStyle={` color: black; `}>Solutions</Title>
            <div className="button-wrapper">
              {
                (show === 'SOLUTIONS') ? (
                  (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
                    <>
                        <Button 
                        text="Sort by"
                        bordered
                        textColor={Colors.color12}
                        buttonStyle={` font-weight: bold; width: 136px; display: flex; justify-content: space-around; `}
                        iconRight
                        onClick={() => this.setState({ sortDropdown: !sortDropdown })}
                        showDropdown={sortDropdown}
                        dropdownData={[
                          {
                            id: 1,
                            name: 'Title, A-Z',
                            sortBy: 'title,asc' 
                          },
                          {
                            id: 2,
                            name: 'Title, Z-A',
                            sortBy: 'title,desc'
                          }
                        ]}
                        dropdownHeight={`60px`}
                        onSelectSort={(data) => {
                          this.setState({ loading: true, sortBy: data, })
                          this.props.getSolutions({ data: { sortBy: data.sortBy, page: this.page, limit: AppConfig.DEFAULT_LIMIT } })
                        }}
                        selectedData={sortBy.name}
                      />
                      <div style={{ width: 25, }} />
                      <Button
                        text={"Create New Listing"}
                        buttonStyle={` width: 315px; `}
                        textStyle={` font-weight: 800; `}
                        onClick={()=> this.openModal()}
                      />
                    </>
                  )
                ) : (
                  (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN) && (
                    <Button
                      text={"Create New Category"}
                      buttonStyle={` width: 315px; `}
                      textStyle={` font-weight: 800; `}
                      onClick={()=> this.openModal()}
                    />
                  )
                )
              }
            </div>
          </div>
          <Row style={{ marginBottom: '30px', }}>
            <Col lg={3}>
              <Button 
                text="Listings"
                backgroundColor={show === 'SOLUTIONS' ? Colors.color10 : Colors.color9}
                textColor={show === 'SOLUTIONS' ? undefined : Colors.color7}
                onClick={() => {
                  this.setState({ show: 'SOLUTIONS' })
                  this.props.getSolutions({ data: { page: this.page, limit: AppConfig.DEFAULT_LIMIT } })
                  this.props.getSolutionCategories({ data: {} })
                  this.getTotalSolutions()
                }}
              />
            </Col>
            <Col lg={3}>
              <Button 
                text="Categories"
                backgroundColor={show === 'CATEGORIES' ? Colors.color10 : Colors.color9}
                textColor={show === 'CATEGORIES' ? undefined : Colors.color7}
                onClick={() => {
                  this.setState({ show: 'CATEGORIES' })
                  this.props.getSolutions({ data: {} })
                  this.props.getSolutionCategories({ data: { page: this.pageCategories, limit: AppConfig.DEFAULT_LIMIT } })
                  this.getTotalSolutionCategories()
                }}
              />
            </Col>
          </Row>
          {
            loading ?
              <div>LOADING...</div>
            :           
            show === 'SOLUTIONS' ?
              <div className="row">
                <Container>
                  <Row>
                    {solutionsData.map((data, i) => {
                      console.log('data  solution => ', data)
                      return(
                        <Card 
                          {...data}
                          onEdit={() => history.push(`/solution/${data.id}`)}
                          onDelete={()=> this.onDelete(data.id)}
                          image={
                            data.SolutionImages.length > 0 && 
                            (data.SolutionImages[0].imageUrl ||
                              (data.SolutionImages[0].imageUrlChinese &&
                                data.SolutionImages[0].imageUrlChinese))
                              ? data.SolutionImages[0].imageUrl ||
                                data.SolutionImages[0].imageUrlChinese
                              : null
                          }
                          content={data.SolutionContent && data.SolutionContent.content}
                          key={i.toString()}
                          tabActive={"solutions"}
                          isSolutions
                        />
                      )
                    })}
                  </Row>
                </Container>
                {
                  (!loadingPagination && pageNumbers.length > 1) &&
                    <div className="center">
                      <div className="pagination">
                      <p className={parseInt(this.page) === 1 && 'cursor-blocked'} onClick={parseInt(this.page) > 1 && this.onPressPreviousPage}>&laquo;</p>
                      {
                        pageNumbers.map(number => {
                          return (
                            <a onClick={() => this.onPressPage(number)} className={this.page && parseInt(this.page) === number ? 'active' : ''}>{number}</a>
                          )
                        })
                      }
                      <p className={parseInt(this.page) === pageNumbers.length && 'cursor-blocked'} onClick={parseInt(this.page) < pageNumbers.length && this.onPressNextPage}>&raquo;</p>
                      </div>
                    </div>
                }
              </div>
            :
              <div className="row">
                <Container>
                  <Row>
                    {categories.map((data, i) => {
                      return(
                        <Card 
                          {...data}
                          onEdit={() => history.push(`/solution-category/${data.id}`)}
                          onDelete={()=> this.onDelete(data.id)}
                          column={3}
                          image={data.imageUrl ? data.imageUrl : data.imageUrlChinese}
                          imageStyle={` width: 120px; height: 120px; margin: 0 auto; `}
                          titleStyle={` margin-top: 20px; border-top: 1px solid #CDD6E2; padding-top: 18px; `}
                          key={i.toString()}
                          tabActive={"categorySolutions"}
                          isSolutions
                        />
                      )
                    })}
                  </Row>
                  {
                    (!loadingPaginationCategories && pageNumberCategories.length > 1 && show !== 'SOLUTIONS') &&
                    <div className="center">
                      <div className="pagination">
                      <p className={parseInt(this.pageCategories) === 1 && 'cursor-blocked'} onClick={parseInt(this.pageCategories) > 1 && this.onPressPreviousPageCategories}>&laquo;</p>
                      {
                        pageNumberCategories.map(number => {
                          return (
                            <a onClick={() => this.onPressPageCategories(number)} className={this.pageCategories && parseInt(this.pageCategories) === number ? 'active' : ''}>{number}</a>
                          )
                        })
                      }
                      <p className={parseInt(this.pageCategories) === pageNumberCategories.length && 'cursor-blocked'} onClick={parseInt(this.pageCategories) < pageNumberCategories.length && this.onPressNextPageCategories}>&raquo;</p>
                      </div>
                    </div>
                  }
                </Container>
              </div>
          }
        </div>
      </StyledSolution>
    )
  }
}

const mapStateToProps = state => {
  return {
    solutions: state.solutions.list,
    solutionCategories: state.solutions.solutionCategories,
    deleteSolutionResult: state.solutions.deleteSolution,
    addCategoryResult: state.solutions.addCategory,
    addSolutionResult: state.solutions.addSolution,
    deleteCategoryResult: state.solutions.deleteCategory,
    account: state.auth.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSolutions: (params) => dispatch(SolutionsActions.getSolutionsRequest(params)),
    getSolutionCategories: (params) => dispatch(SolutionsActions.getSolutionCategoriesRequest(params)),
    deleteSolution: (params) => dispatch(SolutionsActions.deleteSolutionRequest(params)),
    addSolution: (params) => dispatch(SolutionsActions.addSolutionRequest(params)),
    addCategory: (params) => dispatch(SolutionsActions.addSolutionCategoryRequest(params)),
    deleteCategory: (params) => dispatch(SolutionsActions.deleteSolutionCategoryRequest(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Solution)