import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledTable = styled.div`
  margin-top: 20px;

  .-highlight {
    cursor: pointer;
  }

  .top-filter {
    background-color: ${Colors.color3};
    border-radius: 10px 10px;
    margin-bottom: 10px;
  }

  .buttonTableContainer {
    display: flex;
    flex-direction: row;
    padding: 15px;
  }

  .table-header {
    background-color: ${Colors.color6};
    height: 40px;
    color: ${Colors.color12};
    font-weight: 800;
    text-align: left;
    ${Fonts.FontRegular}
    ${Fonts.size.size16}
  }

  .column {
    color: ${Colors.color12};
    font-weight: 800;
    padding: 20px 5px;
    ${Fonts.FontRegular}
    ${Fonts.size.size16}
  }

  .actions-column {
    display: flex;
    align-items: center;
  }
  
  .last-column {
    display: flex;
    align-items: center;
    color: ${Colors.color11};
    font-weight: 800;
    ${Fonts.FontRegular}
    ${Fonts.size.size12}
  }

  .header-no {
    text-align: center;
  }

  .text-no {
    text-align: center;
    padding: 20px;
  }

  .icon-container {
    display: flex;
  }

  .trash-wrapper {
    background-color: ${Colors.color13};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
  }

  .edit-wrapper {
    background-color: ${Colors.color11};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
  }

  .dots-wrapper {
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
  }

  .export-wrapper {
    background-color: ${Colors.color14};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
  }

  .edit, .delete, .export, .transfer {
    width: 11px;
    height: 11px;
  }

  .button-view {
    border: 1.5px solid ${Colors.color11};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    flex: 1;
    margin: 0 15px 0 0;
  }

  .column-checkbox {
    margin: auto;
  }

  .searchbar-container {
    margin-top: -15px;
    padding: 0 15px;
    display: flex;
  }

  .filter-button {
    display: flex;
    align-items: center;
    border-width: 1.5px 1.5px 1.5px 0;
    border-style: solid;
    border-color: ${Colors.color19};
    width: 140px; 
    height: 38px;
    justify-content: space-around;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    overflow: hidden;
  }

  .filter-text {
    ${Fonts.FontRegular}
    font-size: 14px;
    font-weight: bold;
    margin-left: 11px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const ButtonDementia = css`
  width: 80px;
  height: 40px;
  .button-text {
    ${Fonts.size.size16}
  }
`

export const ButtonCaregiver = css`
  min-width: 120px;
  padding: 10px;
  height: 40px;
  .button-text {
    ${Fonts.size.size16}
  }
`

export const SearchBarStyle = `
  background: ${Colors.color3};
  border: 1.5px solid ${Colors.color19};
  border-radius: 10px 10px;
  height: 38px;
  margin-left: 15px;
`

export const filterButton = `
  display: flex;
  align-items: center;
  border-width: 1.5px 1.5px 1.5px 0;
  border-style: solid;
  border-color: ${Colors.color19};
  width: 140px; 
  height: 38px;
  justify-content: space-around;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  overflow: hidden;
`