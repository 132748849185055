import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import PropTypes from 'prop-types';
import close from '../../assets/images/close.png';
import { Link } from 'react-router-dom';
import { StyledModal } from './StyledModal';
import Button from '../button'

import { Colors, } from '../../themes'

const windowHeight = window.innerHeight
export default class Dialog extends Component {
  render() {
    const { description, height, cancelText, okText, cancelBordered, cancelBorderedColor, okBordered, okBorderColor, okTextColor, onOk, okBackgroundColor, onCancel, okDisabled, cancelBackgroundColor, cancelTextColor, isDeletedReason, deleteReason, setDeleteReason, yesOnLeft } = this.props
    return (
      <StyledModal {...this.props.additionalStyle} height={height}>
        <Modal
          visible={this.props.isVisible}
          width={this.props.width}
          effect="fadeInUp"
          onClickAway={this.props.isClose}
        >
         
            <div className="container-modal" style={{  overflow: 'auto' }}> 
              <div>
                <div style={{display: "flex", justifyContent: 'space-between', }}>
                  <p className="title">{this.props.title}</p>
                  {
                    this.props.showXClose && 
                    <a href="javascript:void(0);" onClick={this.props.isClose}>
                      <img src={close} className="image" />
                    </a>
                  }
                </div>
                {
                  description !== '' &&
                  <div>
                    <p className="description">{description}</p>
                  </div>
                }{
                  isDeletedReason && 
                  <div style={{
                    marginTop: '25px'
                  }}>
                    <p style={{
                      marginLeft: '15px',
                      color: Colors.color2,
                      fontWeight: 'bold',
                      marginBottom: '15px'
                    }}>
                      Reason
                    </p>
                    <textarea style ={{
                      marginLeft: '15px',
                      height: '80px',
                      width: '97%',
                      border: '1px solid #B1C2D8',
                      borderRadius: '5px',
                      padding: '10px'
                    }}
                    value={deleteReason}
                    onChange={setDeleteReason}
                    />
                  </div>
                }
                {this.props.children}
              </div>
              <div className="button-container">
                {
                  cancelText !== '' && !yesOnLeft &&
                  <>
                    <div style={{ flex: 1, }}>
                      <Button 
                        text={cancelText}
                        bordered={cancelBordered}
                        textColor={cancelTextColor ? cancelTextColor : Colors.color2}
                        textStyle={`font-weight: bold; text-align: center;`}
                        onClick={this.props.onCancel ? this.props.onCancel : this.props.isClose}
                        backgroundColor={cancelBackgroundColor}
                      />
                    </div>
                    <div style={{ width: '15px', }} />
                  </>
                }
                {
                  okText !== '' &&
                  <div style={{ flex: 1, }}>
                    <Button 
                      text={okText}
                      textColor={okTextColor}
                      textStyle={`font-weight: bold; text-align: center;`}
                      borderColor={okBorderColor}
                      onClick={onOk}
                      backgroundColor={okBackgroundColor}
                      disabled={okDisabled}
                    />
                  </div>
                }
                 {
                  cancelText !== '' && yesOnLeft &&
                  <>
                    <div style={{ flex: 1, marginLeft: '10px' }}>
                      <Button 
                        text={cancelText}
                        bordered={cancelBordered}
                        borderColor={cancelBorderedColor}
                        textColor={cancelTextColor ? cancelTextColor : Colors.color2}
                        textStyle={`font-weight: bold; text-align: center;`}
                        onClick={this.props.onCancel ? this.props.onCancel : this.props.isClose}
                        backgroundColor={cancelBackgroundColor}
                      />
                    </div>
                  </>
                }
              </div>
            </div>
        </Modal>
      </StyledModal>
    )
  }
}


Dialog.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  isClose: PropTypes.func,
  title: PropTypes.string,
  width: PropTypes.string,
  showXClose: PropTypes.bool,
  additionalStyle: PropTypes.any,
  description: PropTypes.description,
  height: PropTypes.string, 
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  cancelBordered: PropTypes.bool,
  okBordered: PropTypes.bool,
  okBorderColor: PropTypes.string,
  okTextColor: PropTypes.string,
  onOk: PropTypes.func,
  okBackgroundColor: PropTypes.string,
  onCancel: PropTypes.func,
  okDisabled: PropTypes.bool,
  setDeleteReason: PropTypes.func,
}

Dialog.defaultProps = {
  children: null,
  isVisible: false,
  isClose: null,
  title: '',
  width: '',
  showXClose: true,
  additionalStyle: {},
  description: '',
  height: `${windowHeight - 106}px`,
  cancelText: '',
  okText: '',
  cancelBordered: true,
  okBordered: true,
  okBorderColor: Colors.color11,
  okTextColor: Colors.color11,
  onOk: () => null,
  okBackgroundColor: 'transparent',
  onCancel: null,
  okDisabled: false,
}