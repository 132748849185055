import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from "memoize-one";
import { EditorState, convertToRaw, ContentState, convertFromRaw,} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import imageCompression from 'browser-image-compression'

import { StyledEditForm } from './styles/EditFormStyles'
import Form from '../components/form'
import DialogInfo from '../components/dialog-info'
import { Colors } from '../themes'
import DialogPopUp from "../components/modal"

import BenefitsActions from '../redux/BenefitsRedux'

import { addNotification, deleteNotificationByQuery } from '../api/NotificationAPI';

export class EditForm extends Component {
  constructor(props) {
    super(props)

    const { benefits, match } = props
    
    let benefit = benefits && benefits.payload && benefits.payload.payload.data.filter(data => data.id === parseInt(match.params.id))[0]
    const { id, title, titleChinese, BenefitContent, BenefitContentChinese, BenefitVendor, BenefitImages, BenefitCategories, tags, tagsChinese } = benefit || ''
    const contentBlock = htmlToDraft(BenefitContent.content);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const descriptions = EditorState.createWithContent(contentState);

    let descriptionsChinese

    if(BenefitContent.contentChinese) {
      const contentBlockChinese = htmlToDraft(BenefitContent.contentChinese);
      const contentStateChinese = ContentState.createFromBlockArray(contentBlockChinese.contentBlocks);
      descriptionsChinese = EditorState.createWithContent(contentStateChinese);
    }
    
    this.state = {
      id,
      title,
      titleChinese,
      descriptions, //BenefitContent.content
      descriptionsChinese,
      vendorName: BenefitVendor.name,
      vendorAddress: BenefitVendor.address,
      vendorContactNumber: BenefitVendor.contactNumber,
      vendorWebsite: BenefitVendor.website,
      vendorNameChinese: BenefitVendor.nameChinese,
      vendorAddressChinese: BenefitVendor.addressChinese,
      vendorContactNumberChinese: BenefitVendor.contactNumberChinese,
      vendorWebsiteChinese: BenefitVendor.websiteChinese,
      listingCategory: '',
      images: BenefitImages,
      newImages: [],
      imageIndex: null,
      loading: false,
      benefitCategories: [],
      selectedCategory: BenefitCategories ? BenefitCategories[0] : null,
      imagesDeleted: [],
      imagesDeletedChinese: [],
      sendNotification: false,
      infoDialogShow: false,
      infoDescription: '',
      tags: tags ? tags.split('--').map(item => ({ id: item, text: item, })) : [],
      tagsChinese: tagsChinese ? tagsChinese.split('--').map(item => ({ id: item, text: item, })) : [],
      dialogPopUp: {
        show: false,
        title: 'Confirm Listing',
        content: 'Are you sure want to create this listing?',
        okText: 'Yes, Confirm',
        cancelText: 'Cancel',
        state: 'listing'
      }
    }

    this.update = false
  }

  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  onChangeImage = async (e, imageUrlKey, imageId) => {
    const { imageIndex, } = this.state
    const imageFile = e.target.files[0]

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const reader = new FileReader()
      reader.onloadend = () => {
        let newImages = [...this.state.newImages]
        let images = [...this.state.images]
        let image = images[imageIndex]
        let changedImage = {
          ...image,
          imageId: image ? image.id : null,
          [imageUrlKey === 'base64ImageChinese' ? 'imageUrlChinese' : 'imageUrl']: reader.result
        }

        if(newImages.length === 0) {
          newImages.push({ order: 1, [imageUrlKey]: reader.result, imageId: image ? image.id : null })
        } else {
          for(let i=0; i < newImages.length; i++) {
            if(!newImages[i][imageUrlKey]){
              newImages[i][imageUrlKey] = reader.result
              newImages[i].imageId = image ? image.id : null
              break;
            } else if (i === newImages.length - 1) {
              newImages.push({ order: 1, [imageUrlKey]: reader.result, imageId: image ? image.id : null})
              break;
            } else {
              continue;
            }
          }
        }
        images.splice(imageIndex, 1, changedImage)
  
        this.setState({ images, newImages, })
        e.target.value = null;
      } 

      reader.readAsDataURL(compressedFile);
    } catch (error) {
    }

  };

  onValidationListing= () => {
    const { selectedCategory, id, title, titleChinese, descriptions, descriptionsChinese, vendorName, vendorAddress, vendorContactNumber, vendorWebsite, vendorNameChinese, vendorAddressChinese, vendorContactNumberChinese, vendorWebsiteChinese, tags, tagsChinese, dialogPopUp } = this.state

    if(!selectedCategory || !id || !title || !titleChinese || !descriptions || !descriptionsChinese || !vendorName || !vendorContactNumber || !vendorWebsite || !vendorNameChinese || !vendorContactNumberChinese || !vendorWebsiteChinese || !tags || !tagsChinese) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: 'Confirm Listing',
          content: 'Are you sure want to create this listing?',
          state: 'listing'
        }
      })
    } else {
      return this.onEdit()
    }
  }

  onEdit = () => {
    const { selectedCategory, id, title, titleChinese, descriptions, descriptionsChinese, vendorName, vendorAddress, vendorContactNumber, vendorWebsite, vendorNameChinese, vendorAddressChinese, vendorContactNumberChinese, vendorWebsiteChinese, newImages, imagesDeleted, tags, tagsChinese, dialogPopUp } = this.state

    this.setState({ loading: true, dialogPopUp: {...dialogPopUp, show: false} })

    const content = draftToHtml(convertToRaw(descriptions.getCurrentContent()));
    const contentChinese = draftToHtml(convertToRaw(descriptionsChinese.getCurrentContent()));

    this.props.updateBenefit({
      id,
      data: {
        categoryId: selectedCategory.id,
        title,
        titleChinese,
        content,
        contentChinese,
        vendor: {
          name: vendorName,
          address: vendorAddress,
          contactNumber: parseInt(vendorContactNumber),
          website: vendorWebsite,
          nameChinese: vendorNameChinese,
          addressChinese: vendorAddressChinese,
          contactNumberChinese: parseInt(vendorContactNumberChinese),
          websiteChinese: vendorWebsiteChinese,
        },
        images: newImages.length > 0 ? newImages : undefined,
        imagesDeleted: imagesDeleted.length > 0 ? imagesDeleted : undefined,
        tags: tags.map(item => item.text).join('--'),
        tagsChinese: tagsChinese.map(item => item.text).join('--'),
      }
    })

    this.update = true
  }

  updateResult = memoize(async data => {
    const { fetching, payload, error, } = data

    if (!fetching && this.update) {
      if (payload) {
        if (payload.type === 'SUCCESS') {
          if (this.state.sendNotification) {   
            let benefit = payload.payload.data[0]
            const data = {
              title: `Updated benefit at ${benefit.title}`,
              text: `${this.htmltoText(benefit.BenefitContent.content).substring(0, 50)}...`,
              finishedPicture: benefit.BenefitImages[0].imageUrl,
              name: `BENEFIT_${benefit.id}_UPDATED`,
              platform: 'Android, iOS',
              type: 'UPDATE_BENEFITS',
              delivery: `NOW`,
              frequency: null,
              dateSend: moment(new Date()),
              accountType: 'ALL',
              age: 'ALL',
              typeDementia: 'ALL',
              language: 'ALL',
              gender: 'ALL',
              stageDementia: 'ALL',
              dataNotification: {
                targetScreen: 'BenefitDetailScreen',
                benefitId: `${benefit.id}`,
              }
            };
            let pushNotification = await addNotification(data)
            if (pushNotification.type === 'ERROR') {
              this.onDialogInfo('Failed to send push notification')
            }
            console.log('push notification edit benefit => ', pushNotification)
          }
          this.setState({
            loading: false,
            errorText: '',
          })
          this.onDialogInfo('Data Updated')
          this.props.getBenefits({})
          this.props.history.goBack()
        } else {
          this.onDialogInfo('ERROR')
          this.setState({
            errorText: payload.error ? payload.error : payload.message ? payload.message : 'ERROR',
            loading: false,
          })
        }
      }

      if (error) {
        this.onDialogInfo(error.message)
        this.setState({
          errorText: 'Something wrong, please check your internet connection',
          loading: false,
        })
      }
      
      this.update = false
    }
  })

  onDeleteImage = (imageData, imageIndex, indexKey) => {
    // let imagesDeleted = indexKey === 'imageUrlChinese' ? [...this.state.imagesDeletedChinese] : [...this.state.imagesDeleted]
    let imagesDeleted = this.state.imagesDeleted
    let images = [...this.state.images]
    let image = images[imageIndex]

    if (image && image.id) {
      // looping imagesDeleted
      if(imagesDeleted.length > 0) {
        for(let i = 0; i < imagesDeleted.length; i++) {
          if(imagesDeleted[i] && imagesDeleted[i].imageId && imagesDeleted[i].imageId === image.id) {
            imagesDeleted[i] = {...imagesDeleted[i], imageUrl: indexKey === 'imageUrl' ? true : false, imageUrlChinese : indexKey === 'imageUrlChinese' ? true : false}
          } else {
            imagesDeleted.push({ imageId: image ? image.id : null, imageUrl: indexKey === 'imageUrl' ? true : false, imageUrlChinese : indexKey === 'imageUrlChinese' ? true : false })
          }
        }
      } else {
          imagesDeleted.push({ imageId: image ? image.id : null, imageUrl: indexKey === 'imageUrl' ? true : false, imageUrlChinese : indexKey === 'imageUrlChinese' ? true : false })
      }
    }

    const emptyImageState = {
      [indexKey] : ''
    }
    images[imageIndex] = {...images[imageIndex], ...emptyImageState}

    if(image.imageUrl === '' && image.imageUrlChinese === '') {
      images.splice(imageIndex, 1)
    }

    this.setState({ images, imagesDeleted })
  }

  onEditorStateChange = (descriptions) => {
    this.setState({
      descriptions,
    });
  };

  onEditorChineseStateChange = (descriptionsChinese) => {
    this.setState({
      descriptionsChinese,
    });
  };

  onDialogInfo = (val) => {
    this.setState({infoDescription: val, infoDialogShow: true})
  }

  htmltoText = (html) => {
    let text = html;
    text = text.replace(/\n/gi, "");
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
    text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
    text = text.replace(/<\/div>/gi, "\n\n");
    text = text.replace(/<\/li>/gi, "\n");
    text = text.replace(/<li.*?>/gi, "  *  ");
    text = text.replace(/<\/ul>/gi, "\n\n");
    text = text.replace(/<\/p>/gi, "\n\n");
    text = text.replace(/<br\s*[\/]?>/gi, "\n");
    text = text.replace(/<[^>]+>/gi, "");
    text = text.replace(/^\s*/gim, "");
    text = text.replace(/ ,/gi, ",");
    text = text.replace(/ +/gi, " ");
    text = text.replace(/\n+/gi, "\n\n");
    // this is for remove &nbsp; 
    text = text.replace(/&nbsp;/g, '');
    return text;
  };

  render() {
    const { 
      title, 
      titleChinese,
      infoDialogShow, 
      infoDescription, 
      descriptions, 
      descriptionsChinese,
      vendorName, 
      vendorAddress, 
      vendorContactNumber, 
      vendorWebsite, 
      vendorNameChinese, 
      vendorAddressChinese, 
      vendorContactNumberChinese, 
      vendorWebsiteChinese, 
      images, 
      newImages, 
      imageIndex, 
      listingCategory, 
      loading, 
      selectedCategory, 
      tags, 
      tagsChinese,
      dialogPopUp
    } = this.state

    this.updateResult(this.props.updateBenefitResult)

    return (
      <StyledEditForm>
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        <DialogPopUp
          isVisible={dialogPopUp.show}
          okText={dialogPopUp.okText}
          cancelText={dialogPopUp.cancelText}
          onOk={this.onEdit}
          onCancel={()=>this.setState({dialogPopUp: {...dialogPopUp, show: false}})}
          isClose={()=>this.setState({dialogPopUp: {...dialogPopUp, show: false}})}
          height={'250px'}
          okBackgroundColor={Colors.color42}
          okTextColor={'white'}
        >
          <p style={{ color: Colors.color19, fontSize: '26px', fontWeight: '800' }}>{dialogPopUp.title}</p>
          <p style={{ color: Colors.color2, fontSize: '17px', fontWeight: '700', marginTop: '30px' }}>Any empty information will only appear in English.</p>
          <p style={{ color: Colors.color2, fontSize: '17px', fontWeight: '700', marginTop: '10px' }}>{dialogPopUp.content}</p>
        </DialogPopUp>
        <Form
          {...this.props}
          title={'Edit Benefits'}
          benefitTitle={title}
          benefitTitleChinese={titleChinese}
          descriptions={descriptions}
          descriptionsChinese={descriptionsChinese}
          vendorName={vendorName}
          vendorAddress={vendorAddress}
          vendorContactNumber={vendorContactNumber}
          vendorWebsite={vendorWebsite}
          vendorNameChinese={vendorNameChinese}
          vendorAddressChinese={vendorAddressChinese}
          vendorContactNumberChinese={vendorContactNumberChinese}
          vendorWebsiteChinese={vendorWebsiteChinese}
          listingCategory={listingCategory}
          onChangeText={this.onChangeText}
          images={images}
          newImages={newImages}
          onChangeImage={this.onChangeImage}
          onSelectImage={(imageIndex) => this.setState({ imageIndex, })}
          // onSave={this.onEdit}
          onSave={this.onValidationListing}
          loading={loading}
          dropdownData={this.props.benefitCategories.payload ? this.props.benefitCategories.payload.payload.data : []}
          dropdownPropertyName="title"
          onSelectDropdown={(selectedCategory) => this.setState({ selectedCategory, })}
          selectedCategory={selectedCategory}
          onDeleteImage={this.onDeleteImage}
          onEditorStateChange={this.onEditorStateChange}
          onEditorChineseStateChange={this.onEditorChineseStateChange}
          onDiscard={() => this.props.history.goBack()}
          onChangeSendNotification={(sendNotification) => this.setState({ sendNotification })}
          inputTagsProps={{
            tagsData: tags,
            onAddTag: (tag) => {
              this.setState(state => ({ tags: [...state.tags, tag] }));
            },
            onDeleteTag: (i) => {
              this.setState({
                tags: tags.filter((tag, index) => index !== i),
              });
            },
            onDragTag: (tag, currPos, newPos) => {
              const tags = [...this.state.tags];
              const newTags = tags.slice();

              newTags.splice(currPos, 1);
              newTags.splice(newPos, 0, tag);

              // re-render
              this.setState({ tags: newTags });
            },
            onClearData: () => this.setState({ dialog: false, ...defaultState, })
          }}
          inputTagsChineseProps={{
            tagsData: tagsChinese,
            onAddTag: (tag) => {
              this.setState(state => ({ tagsChinese: [...state.tagsChinese, tag] }));
            },
            onDeleteTag: (i) => {
              this.setState({
                tagsChinese: tagsChinese.filter((tag, index) => index !== i),
              });
            },
            onDragTag: (tag, currPos, newPos) => {
              const tags = [...this.state.tags];
              const newTags = tagsChinese.slice();

              newTags.splice(currPos, 1);
              newTags.splice(newPos, 0, tag);

              // re-render
              this.setState({ tagsChinese: newTags });
            },
            onClearData: () => this.setState({ dialog: false, ...defaultState, })
          }}
        />
      </StyledEditForm>
    )
  }
}

const mapStateToProps = (state) => ({
  benefits: state.benefits.list,
  updateBenefitResult: state.benefits.updateBenefit,
  benefitCategories: state.benefits.benefitCategories,
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateBenefit: (params) => dispatch(BenefitsActions.updateBenefitRequest(params)),
    getBenefits: (params) => dispatch(BenefitsActions.getBenefitsRequest(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditForm)
