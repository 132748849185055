import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom' 
import { connect } from 'react-redux'

import AuthActions from '../../redux/AuthRedux'

import { StyledSidebar } from "./StyledSidebar";

import { adminSidebar, partnerSidebar, viewerSidebar } from '../../data/SidebarData'
import {signOut} from '../../api/AdminAPI'

import { USER_TYPE } from '../../constants'
import {Providers, ProviderState} from '@microsoft/mgt-element';

function Sidebar(props) {
  const { inputProps, containerStyle, account, history, signout, } = props
  const accountData = account.payload && account.payload.data.account
  const sidebar = (accountData.type === USER_TYPE.ADMIN || accountData.type === USER_TYPE.SUPERADMIN) ? adminSidebar : accountData.type === USER_TYPE.PARTNER ? partnerSidebar : accountData.type === USER_TYPE.VIEWER ? viewerSidebar : []

  const handleSignOut = async () => {
    if(accountData && accountData.uid) {
      try {
        await signOut({
          uid: accountData.uid,
          action: 'Sign Out'
        })
        localStorage.clear();
        // if (accountData.username !== "embreoadmin" && (accountData.type === USER_TYPE.ADMIN || accountData.type === USER_TYPE.SUPERADMIN)){
        //   await Providers.globalProvider.logout()
        // }
      } catch (err) {
        console.log(err.message)
      }
    }
  }

  return (
    <StyledSidebar containerStyle={containerStyle}>
      {sidebar.map((data, i) => {
        return (
          <Link className={`itemBox ${
            (
              location.pathname === data.path || 
              (data.path === '/users' && location.pathname === '/import-user')  
            ) && 
              `activeBox`}`
            } key={i.toString()} to={data.path}  onClick={() =>  window.scrollTo({top: 0, left: 0, behavior: 'smooth' })}>
            <p className="text">{data.title}</p>
          </Link>
        )
      })}
      <div className={`itemBox`} onClick={() =>  {
          handleSignOut()
          signout()
          history.replace('/')
          {/* localStorage.clear() */}
        }}>
        <p className="text">Sign Out</p>
      </div>
    </StyledSidebar>
  )
}

Sidebar.propTypes = {
  inputProps: PropTypes.any,
  containerStyle: PropTypes.string,
}

Sidebar.defaultProps = {
  inputProps: {},
  containerStyle: ``,
}

const mapStateToProps = (state) => ({
  account: state.auth.account,
})

const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => dispatch(AuthActions.signout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
