// a library to wrap and simplify api calls
import apisauce from 'apisauce'

// our "constructor"
const create = (baseURL, benefitsURL, solutionsURL, notificationsURL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
    },
    timeout: 60000
  })

  const apiBenefits = apisauce.create({
    // base URL is read from the "constructor"
    baseURL: benefitsURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
    },
    timeout: 60000
  })

  const apiSolutions = apisauce.create({
    // base URL is read from the "constructor"
    baseURL: solutionsURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
    },
    timeout: 60000
  })

  const apiNotifications = apisauce.create({
    // base URL is read from the "constructor"
    baseURL: notificationsURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
    },
    timeout: 60000
  })
  
  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const verifyToken = (params) => {
    const url = params.partner ? `/public/credential/verifyPartnerToken` : '/public/credential/verifyToken'
    return api.post(url, {}, { headers: { 'Authorization': `${params.token}`} })
  }

  const signin = (params) => {
    return api.post('/admin/signin', params.data)
  }
  const signup = (params) => {
    let apiUrl = params.signAs === 'Caregiver' ? '/public/credential/signupCaregiver' : '/public/credential/signupDementia' 
    return api.post(apiUrl, params.data)
  } 

  const isUserExist = (params) => {
    let apiUrl = params.signAs === 'Caregiver' ? '/public/credential/checkCaregiverRegistration' : '/public/credential/checkDementiaRegistration'
    return api.post(apiUrl , params.data)
  } 

  const getUsers = (params) => {
    const url = (params.type === "ADMIN" || params.type === "SUPER_ADMIN" || params.type === "VIEWER" )? '/admin/users' : '/partner/users'
    return api.get(url, params.data)
  }

  const addUser = (params) => api.post('/public/credential/signupCaregiver', params.data)

  const getSafeReturn = (data) => {
    const url = (data.type === "ADMIN" || data.type === "SUPER_ADMIN" || data.type === "VIEWER") ? '/admin/safe-return' : '/partner/safe-return' 
    return api.get(url, data.params)
  } 
  
  const getBenefits = (params) => apiBenefits.get('/benefits', params.data)

  const addBenefit = (params) => apiBenefits.post('/benefits', params.data)
  
  const deleteBenefit = (params) => apiBenefits.delete(`/benefits/${params.id}`)

  const updateBenefit = (params) => apiBenefits.patch(`/benefits/${params.id}`, params.data)

  const getBenefitCategories = (params) => apiBenefits.get('/benefit-category', params.data)

  const addBenefitCategory = (params) => apiBenefits.post('/benefit-category', params.data)

  const deleteBenefitCategory = (params) => apiBenefits.delete(`/benefit-category/${params.id}`, params.data)

  const updateBenefitCategory = (params) => apiBenefits.patch(`/benefit-category/${params.id}`, params.data)

  const getSolutions = (params) => apiSolutions.get('/solutions', params.data)

  const addSolution = (params) => apiSolutions.post('/solutions', params.data)
  
  const deleteSolution = (params) => apiSolutions.delete(`/solutions/${params.id}`)

  const updateSolution = (params) => apiSolutions.patch(`/solutions/${params.id}`, params.data)

  const getSolutionCategories = (params) => apiSolutions.get('/solution-category', params.data)

  const addSolutionCategory = (params) => apiSolutions.post('/solution-category', params.data)

  const deleteSolutionCategory = (params) => apiSolutions.delete(`/solution-category/${params.id}`, params.data)

  const updateSolutionCategory = (params) => apiSolutions.patch(`/solution-category/${params.id}`, params.data)

  const getNotifications = (params) => apiNotifications.get(`/notifications`, params.data)

  const getDashboardPartner = (params) => api.get(`/admin/dashboard`, params)

  const getDailySignUp = params => api.get('/admin/users', params)

  const getReportMissing = (params) => api.get('/admin/getReportMissing', params)
  const editReportMissing = (params) => api.patch('/admin/saveNotes', params)

  const signin2FA = (params) => api.post('/admin/signin2fa', params.data)
  const verifyQR = (params) => api.post('/admin/verifyQr', params.data)
  const signinSSO = (params) => api.post('/admin/checkAccount', params.data)
  const getAdmin = (params) => api.get('/admin/getAdmin', params.data)
  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    api,
    apiBenefits,
    apiSolutions,
    apiNotifications,
    verifyToken,
    signin,
    signup,
    getSafeReturn,
    isUserExist,
    getUsers,
    getBenefits,
    addBenefit,
    deleteBenefit,
    updateBenefit,
    getBenefitCategories,
    addBenefitCategory,
    deleteBenefitCategory,
    updateBenefitCategory,
    getSolutions,
    addSolution,
    deleteSolution,
    updateSolution,
    getSolutionCategories,
    addSolutionCategory,
    deleteSolutionCategory,
    updateSolutionCategory,
    getNotifications,
    getDashboardPartner,
    getDailySignUp,
    getReportMissing,
    editReportMissing,
    signin2FA,
    verifyQR,
    signinSSO,
    getAdmin
  }
}

// let's return back our create method as the default.
export default {
  create
}
